<!-- src/components/StudentDashboard.vue -->

<template>

  <!-- STUDENT HEADER -->

  <v-row align="center" class="header-row" v-if="!isLoading">
    <v-col cols="3">
      <v-btn 
        v-if="!$vuetify.display.mobile"  
        variant="text"
        color="primary" 
        prepend-icon="mdi-arrow-left"
        class="header-button" 
        @click="close()"
      >
        Student List
      </v-btn>
      <v-btn 
        v-else
        class="header-button"
        variant="tonal"
        size="small" 
        color="primary"
        icon="mdi-arrow-left"
        @click="close()"
      >
      </v-btn>
    </v-col>
    <v-col cols="6">
      <v-card-text v-if="studentName" class="student-name text-center"> {{ studentName }} </v-card-text>
      <v-card-text v-else class="name-placeholder text-center"> Unknown Student </v-card-text>
    </v-col>
    
    <v-col cols="3" class="d-flex justify-end">
      <v-btn 
        v-if="!$vuetify.display.mobile"
        class="header-button" 
        variant="text"
        color="primary"
        prepend-icon="mdi-account"
        @click="openStudentInformationOverlay"
      > 
        Student Information
      </v-btn>
      <v-btn 
        v-else
        class="header-button" 
        variant="tonal"
        size="small"
        color="primary"
        icon="mdi-account"
        @click="openStudentInformationOverlay"
      >
      </v-btn>
    </v-col>
  </v-row>

  <v-container v-if="!isLoading">
    <v-row>
      <v-col>

        <!-- Lesson cards -->
        <v-container v-if="!isLoading && lessons.length > 0" class="card-container">
          <v-row>
            <v-col :cols="12" :sm="6" :lg="4" v-for="lesson in lessons" :key="lesson.id">
              <v-card class="lesson-card" color="primary" variant="tonal">
                <v-row>
                  <v-col>
                    <v-card-title> {{ formatDate(lesson.timestamp) }} </v-card-title>
                  </v-col>
                </v-row>

                <!-- Debrief summary header -->
                <v-row class="section-row">
                  <v-col cols="1" class="py-1">
                    <v-icon class="pr-1">mdi-star-circle</v-icon>
                  </v-col>
                  <v-col>
                    <v-card-text class="notes-section"> Debrief Summary </v-card-text>
                  </v-col>
                </v-row>

                <!-- Debrief summary -->
                <v-card-text v-if="lesson.debriefSummary" class="lesson-notes py-2">
                  <v-row>
                    <v-col v-for="(blurb, index) in splitSummary(lesson.debriefSummary)" :key="index" cols="12" class="py-1">
                      <v-card variant="tonal" color="primary">
                        <v-card-text>
                          {{ blurb }}
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else class="lesson-notes py-2"> No lesson debrief summary to display. </v-card-text>

                <v-card-actions class="card-actions">
                  <v-btn variant="text" icon="mdi-delete" color="error" @click="openDeleteLessonOverlay(lesson)" v-if="lesson.instructor === this.instructorUid"></v-btn>
                  <v-chip v-if="lesson.instructor !== this.instructorUid" color="secondary" variant="elevated" :elevation="0" class="ml-2"> Lesson by another instructor </v-chip>
                  <v-spacer></v-spacer>
                  <v-btn variant="tonal" @click="openEditLessonOverlay(lesson)" v-if="lesson.instructor === this.instructorUid">Edit</v-btn>
                  <v-btn variant="flat" color="primary" @click="openViewLessonOverlay(lesson)">View</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <!-- No lessons placeholder -->
        <v-container v-if="!isLoading && lessons.length === 0">
          <v-row class="fill-height" align="center" justify="center">
            <v-col cols="12" class="text-center">
              <v-card-text class="placeholder-text">No lesson debriefs to display. Add a lesson to get started!</v-card-text>
            </v-col>
          </v-row>
        </v-container>

      </v-col>
    </v-row>
  </v-container>

    <!-- New lesson button -->
    <v-btn
      v-if="!isLoading"
      prepend-icon="mdi-plus"
      text="Add Lesson"
      color="primary"
      rounded="xl"
      :size="buttonSize"
      class="fab-button"
      @click="openAddLessonOverlay"
    >
    </v-btn>

  <!-- Loading indicator placeholder -->
  <v-container v-if="isLoading" class="fill-height d-flex align-center justify-center">
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
          size="100"
          width="5"
      > <v-icon>mdi-airplane</v-icon>
      </v-progress-circular>
      </v-col>
    </v-row>
  </v-container>

  <!-- Imported components -->
  <AddLesson ref="addLessonOverlay" @flightLessonAdded="handleFlightAdded" @flightLessonError="handleFlightError" />
  <EditLesson ref="editLessonOverlay" @flightLessonEdited="handleFlightEdited" @flightLessonError="handleFlightError" />
  <DeleteLesson ref="deleteLessonOverlay" @flightLessonDeleted="handleFlightDeleted" />
  <ViewLesson ref="viewLessonOverlay" />
  <StudentInformation 
    ref="studentInformationOverlay" 
    @updateSuccessful="handleUpdateSuccessful"
    @updateFailed="handleUpdateFailed"
  />
</template>

<script>
import { 
  deleteFlightLesson, 
  fetchStudentFlights
} from '@/services/firebaseService';
import AddLesson from "@/components/AddLesson.vue";
import EditLesson from "@/components/EditLesson.vue";
import DeleteLesson from "@/components/DeleteLesson.vue";
import ViewLesson from "@/components/ViewLesson.vue";
import StudentInformation from "@/components/StudentInformation.vue";

export default {
  name: 'StudentDashboard',
  components: {
    AddLesson,
    EditLesson,
    DeleteLesson,
    ViewLesson,
    StudentInformation
  },
  emits: [
    'resetStudentDashboard', 
    'updateSuccessful', 
    'updateFailed', 
    'flightLessonAdded', 
    'flightLessonEdited',
    'flightLessonDeleted',
    'flightLessonError'
  ],
  data() {
    return {
      isLoading: false,
      acsLoading: false,
      instructorUid: null,
      student: null,
      studentName: null,
      studentEmail: '',
      lessons: [],
      standards: [],
      standardsOptions: [],
      selectedStandardDetails: {},
      selectedStandard: null,
      matchedTasks: [],
      selectedTask: null,
    };
  },
  methods: {

    // UI NAVIGATION METHODS //

    async open(student, instructorUid) {
      this.student = student;
      this.studentEmail = this.student.email;
      this.studentName = student.fullName;
      this.instructorUid = instructorUid;
      this.initialTrainingCourse = student.trainingCourse;
      this.initialTrainingPath = student.trainingPath;
      await this.loadData();
    },
    close() {
      this.$emit('resetStudentDashboard');
    },

    // CONTENT LOADING METHODS //

    async loadData() {
      this.isLoading = true;
      await this.fetchLessons();
      this.isLoading = false;
    },
    async fetchLessons() {
      try {
        this.lessons = await fetchStudentFlights(this.student.id);
      } catch (error) {
        console.error("Error fetching lessons:", error);
      }
    },

    // UI NAV METHODS //

    openStudentInformationOverlay() {
      this.$refs.studentInformationOverlay.open(this.student, this.instructorUid);
    },
    openAddLessonOverlay() {
      this.$refs.addLessonOverlay.open(this.student, this.instructorUid);
    },
    openEditLessonOverlay(lesson) {
      this.$refs.editLessonOverlay.open(this.student.id, this.instructorUid, lesson);
    },
    openDeleteLessonOverlay(lesson) {
      this.$refs.deleteLessonOverlay.open(lesson);
    },
    openViewLessonOverlay(lesson) {
      this.$refs.viewLessonOverlay.open(lesson);
    },
    handleUpdateSuccessful() {
      this.$emit('updateSuccessful');
    },
    handleUpdateFailed() {
      this.$emit('updateFailed');
    },
    async handleFlightAdded() {
      this.$emit('flightLessonAdded');
      await this.fetchLessons();
    },
    async handleFlightEdited() {
      this.$emit('flightLessonEdited');
      await this.fetchLessons();
    },
    async handleFlightDeleted(lesson) {
      this.$emit('flightLessonDeleted');
      await deleteFlightLesson(this.student.id, lesson.id);
      await this.fetchLessons();
    },
    async handleFlightError() {
      this.$emit('flightLessonError');
    },

    // MISC METHODS //

    formatDate(timestamp) {
      if (!timestamp) return '';
      const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
      const date = new Date(milliseconds);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    },
    splitSummary(summary) {
      return summary.split(/\n\s*\n/).filter(paragraph => paragraph.trim() !== '');
    }
  },
  computed: {
    buttonSize() {
      return this.$vuetify.display.mobile ? 'default' : 'large';
    }
  }
};
</script>

<style scoped>
.header-row {
  margin-inline: 10px;
}

.header-button {
  margin-right: 10px;
  margin-block: 10px;
}

.student-name {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(var(--v-theme-primary));
  font-size: 24px;
  font-weight: 500;
}

.name-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(var(--v-theme-primary), 0.4);
  font-size: 24px;
  font-weight: 500;
}

.placeholder-text {
  color: rgb(var(--v-theme-primary));
  margin: 4px;
}

.card-container {
  padding-block: 10px;
  margin-bottom: 50px;
}

.lesson-card {
  height: 500px;
}

.notes-section {
  padding-block: 0;
  padding-left: 0;
  font-weight: 500;
}

.section-row {
  display: flex;
  margin-inline: 10px;
  margin-block: 2px;
  align-items: center;
  background-color: rgb(var(--v-theme-primary), 0.1); 
  border-radius: 5px;
}

.lesson-notes {
  height: 320px;
  margin: 10px;
  overflow-y: auto;
}

.lesson-notes::-webkit-scrollbar {
  width: 5px;
}

.lesson-notes::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-primary), 0.1);
  border-radius: 10px;
}

.lesson-notes::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-primary), 0.3);
  border-radius: 10px;
}

.lesson-notes::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-primary), 0.8);
}

.card-actions {
  height: 64px;
}

.fab-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: var(--v-theme-primary);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
