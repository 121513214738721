<!-- src/pages/InstructPage.vue -->

<template>
  <v-navigation-drawer 
    expand-on-hover 
    rail
    class="navigation-drawer"
    rail-width="64"
    width="350"
  >
    <InstructNavigationContent
      :userPhoto="userPhoto"
      :userName="userName"
      :truncatedUserEmail="truncatedUserEmail"
      :isPaidUser="isPaidUser"
      @open-billing-portal="openBillingPortal"
      @open-user-profile="openUserProfile"
      @sign-out="signOut"
    />  
  </v-navigation-drawer>

  <v-app-bar v-if="isProfessionalUser || $vuetify.display.mobile" class="top-navigation-bar" density="compact">
    <v-row class="d-flex align-center">
      <v-col cols="2">
        <v-menu
          v-model="menu"
          location="end"
          v-if="$vuetify.display.mobile"
        >
          <template v-slot:activator="{ props }">
            <v-btn icon color="primary" v-bind="props">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-card class="menu-card" width="350">
            <InstructNavigationContent
              :userPhoto="userPhoto"
              :userName="userName"
              :truncatedUserEmail="truncatedUserEmail"
              :isPaidUser="isPaidUser"
              @open-billing-portal="openBillingPortal"
              @open-user-profile="openUserProfile"
              @sign-out="signOut"
            />
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="8" class="d-flex justify-center">
        <v-btn 
          v-if="isProfessionalUser" 
          rounded="xl" 
          class="navigation-button" 
          @click="handleChatClick"
        >
          Chat
        </v-btn>
        <v-btn 
          v-if="isProfessionalUser"
          rounded="xl" 
          variant="tonal"
          class="navigation-button" 
        >
          Instruct
        </v-btn>
      </v-col>
      <v-col cols="2">
        <!-- Placeholder -->
      </v-col>
    </v-row>
  </v-app-bar>

  <!-- Loading indicator placeholder -->
  <v-container v-if="isLoading" class="fill-height d-flex align-center justify-center">
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
          size="100"
          width="5"
      > <v-icon>mdi-airplane</v-icon>
      </v-progress-circular>
      </v-col>
    </v-row>
  </v-container>

  <!-- STUDENTS -->

  <!-- Student list -->
  <v-container class="card-container" v-if="!isLoading && !showStudentDashboard">
    <v-row>
      <v-col
        v-for="student in students"
        :key="student"
        :cols="12" 
        :sm="6"
        :lg="4"
      >
        <v-card
          class="card-item"
          variant="tonal"
          color="primary"
          @click="openStudentDashboard(student)"
        >
          <v-container>
            <v-row class="card-row mx-1">
              <v-col cols="2" class="card-avatar">
                <v-avatar class="avatar-icon">
                  <v-img v-if="student.photoUrl" :src="student.photoUrl"></v-img>
                  <v-icon v-else icon="mdi-account-circle"></v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="10" class="card-info">
                <v-card-title class="student-name" v-if="student.fullName"> {{ student.fullName }} </v-card-title>
                <v-card-title class="name-placeholder" v-else> Unknown Student </v-card-title>
              </v-col>
            </v-row>
            <v-divider class="my-4"></v-divider>
            <v-row dense class="card-details">
              <v-col cols="12">
                <v-card-text v-if="student.trainingCourse" class="rating-chip">{{ student.trainingCourse }} </v-card-text >
                <v-card-text  v-else class="rating-chip"> No training course selected </v-card-text >
              </v-col>
              <v-col cols="12">
                <v-card-text class="card-email"> <strong>Email:</strong> {{ student.email }} </v-card-text>
              </v-col>
              <v-col cols="12">
                <v-card-text v-if="student.trainingPath" class="path-text"> <strong>Training Path:</strong> {{ student.trainingPath }} </v-card-text>
                <v-card-text v-else class="path-text"> <strong>Training Path:</strong> No training path selected </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- No students available placeholder -->
  <v-container v-if="!isLoading && students.length == 0">
    <v-row class="fill-height" align="center" justify="center">
      <v-col cols="12" class="text-center">
        <v-card-text class="placeholder-text">No students to display. Add a new student to get started!</v-card-text>
      </v-col>
    </v-row>
  </v-container>

  <!-- New student button -->
  <v-btn
    v-if="!isLoading && !showStudentDashboard"
    prepend-icon="mdi-plus"
    text="New Student"
    color="primary"
    rounded="xl"
    :size="buttonSize"
    class="fab-button"
    @click="openNewStudentOverlay"
  >
  </v-btn>

  <!-- Snackbar notification -->
  <SnackbarNotification 
    :message="snackbar.message" 
    :color="snackbar.color" 
    :timeout="snackbar.timeout" 
    location="top right"
  />

  <UserProfile ref="userProfile" @profileUpdated="() => showSnackbar('User profile updated', 'info')"/>
  
  <StudentDashboard 
    v-if="showStudentDashboard" 
    ref="studentDetails" 
    @resetStudentDashboard="resetStudentDashboard()"
    @updateSuccessful="() => showSnackbar('Training details updated', 'info')"
    @updateFailed="() => showSnackbar('Training details failed to update', 'error')"
    @flightLessonAdded="() => showSnackbar('Flight lesson added', 'info')"
    @flightLessonEdited="() => showSnackbar('Flight lesson edited', 'info')"
    @flightLessonDeleted="() => showSnackbar('Flight lesson deleted', 'info')"
    @flightLessonError="() => showSnackbar('Error saving flight lesson', 'error')"
  />

  <NewStudent 
    ref="newStudentOverlay" 
    @studentAdded="handleStudentAdded"
    @inviteSent="handleInviteSent"
    @inviteFailed="handleInviteFailed"
  />
</template>

<script>
import SnackbarNotification from "@/components/SnackbarNotification.vue";
import InstructNavigationContent from '@/components/InstructNavigationContent.vue';
import UserProfile from "@/components/UserProfile.vue";
import NewStudent from "@/components/NewStudent.vue";
import StudentDashboard from "@/components/StudentDashboard.vue"; 
import { fetchStudentsForInstructor } from '../services/firebaseService';

export default {
  name: 'InstructPage',
  components: {
    UserProfile,
    NewStudent,
    SnackbarNotification,
    InstructNavigationContent,
    StudentDashboard
  },
  data () {
    return {
      defaultAvatar: require('@/assets/default-avatar.png'),
      assistantAvatar: require('@/assets/orb-animation.png'),
      students: [],
      showStudentDashboard: false,
      selectedStudent: null,
      snackbar: {
        message: '',
        color: '',
        timeout: null,
      },
      initialLoad: false,
      isLoading: false,
      menu: false
    }
  },
  async created() {
    this.$store.dispatch('fetchUser');
    this.initialLoad = true;
    this.isLoading = true;
    await this.loadStudents();
    this.isLoading = false;
    this.initialLoad = false;
  },
  methods: {

    // AUTHENTICATION METHODS //

    async signOut() {
      try {
        await this.$store.dispatch('signOut');
        this.$router.push('/');
      } catch (error) {
        // Error handling if needed
        console.error('Sign Out Error:', error);
      }
    },

    // CONTENT LOADING METHODS //

    async loadStudents() {
      if (!this.initialLoad) {
        this.isLoading = true;
      }
      this.selectedStudent = null;
      this.showStudentDashboard = false;
      try {
        this.students = await fetchStudentsForInstructor(this.userUid);   
      } catch (error) {
        console.error('Failed to load students:', error);
        this.showSnackbar('Failed to load students', 'error');
      }
      if (!this.initialLoad) {
        this.isLoading = false;
      }
    },

    // UI AND MISC METHODS //

    openUserProfile() {
      this.$refs.userProfile.open(this.userUid);
    },
    openNewStudentOverlay() {
      this.$refs.newStudentOverlay.open(this.userUid, this.userName);
    },
    openStudentDashboard(student) {
      this.selectedStudent = student;
      this.showStudentDashboard = true;
    },
    async handleStudentAdded() {
      this.showSnackbar('New student added', 'info');
      await this.loadStudents();
    },
    async handleInviteSent() {
      this.showSnackbar('Email invitation sent to student', 'info');
      await this.loadStudents();
    },
    async handleInviteFailed() {
      this.showSnackbar('Email invitation failed to send', 'error');
      await this.loadStudents();
    },
    async handleEndorsementAdded() {
      this.showSnackbar('New endorsement added', 'info');
      await this.loadEndorsements();
    },
    resetStudentDashboard() {
      this.selectedStudent = null;
      this.showStudentDashboard = false;
      this.loadStudents();
    },
    showSnackbar(message, color) {
      this.snackbar.message = '';
      this.$nextTick(() => {
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.timeout = 3000;
      });
    },
    formatDate(timestamp) {
      if (!timestamp) return '';
      const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
      const date = new Date(milliseconds);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    },

    // NAVIGATION METHODS //

    openLinkInNewTab() {
      window.open(this.testSupplementLink, '_blank');
    },
    handleChatClick(event) {
      if (event.metaKey || event.ctrlKey) {
        window.open('/chat', '_blank');
      } else {
        this.$router.push('/chat');
      }
    },
    async openBillingPortal() {
      window.open('https://billing.stripe.com/p/login/9AQdSS7gffrSgve5kk', '_blank');
    }   
  },
  computed: {
    isLoadingUser() {
      return this.$store.getters.isLoadingUser;
    },
    user() {
      return this.$store.getters.user;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    userUid() {
      return this.$store.state.user ? this.$store.state.user.uid : null;
    },
    userEmail() {
      return this.$store.state.user ? this.$store.state.user.email : '';
    },
    userName() {
      return this.$store.state.user ? this.$store.state.user.displayName : '';
    },
    userPhoto() {
      return this.$store.state.user && this.$store.state.user.photoURL ? this.$store.state.user.photoURL : this.defaultAvatar;
    },
    userType() {
      return this.$store.state.user ? this.$store.state.user.userType : '';
    },
    isPaidUser() {
      return this.$store.state.user ? this.$store.state.user.isPaidUser : false;
    },
    userProduct() {
      return this.$store.state.user && this.$store.state.user.product ? this.$store.state.user.product : [];
    },
    isBasicUser() {
      return this.userProduct === 'Basic';
    },
    isProfessionalUser() {
      return this.userProduct === 'Professional';
    },
    dateCreated() {
      const firestoreTimestamp = this.$store.state.user ? this.$store.state.user.dateCreated : null;
      return firestoreTimestamp ? firestoreTimestamp.toDate() : null;
    },
    truncatedUserEmail() {
      const maxLength = 24; // Maximum length of the email string
      if (this.userEmail && this.userEmail.length > maxLength) {
        return this.userEmail.substring(0, maxLength - 3) + '...';
      }
      return this.userEmail;
    },
    buttonSize() {
      return this.$vuetify.display.mobile ? 'default' : 'large';
    }
  },
  watch: {
    showStudentDashboard(newVal) {
      if (newVal === true && this.selectedStudent !== null) {
        // Ensure the child component is mounted
        this.$nextTick(() => {
          this.$refs.studentDetails.open(this.selectedStudent, this.userUid);
        });
      }
    }
  }
}
</script>

<style scoped>
.top-navigation-bar {
  background-color: rgba(200, 200, 200, 0.2);
  backdrop-filter: blur(10px);
}

.navigation-button {
  margin-inline: 10px;
  color: rgb(var(--v-theme-primary));
}

.navigation-drawer {
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
}

:deep(.menu-card) {
  background-color: rgba(var(--v-theme-primary), 0.8) !important;
  backdrop-filter: blur(10px);
}

.placeholder-text {
  color: rgb(var(--v-theme-primary));
}

.card-container {
  padding-block: 10px;
  margin-bottom: 20px;
}

.card-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
  margin: 10px;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in-out;
  overflow: hidden; /* Add this line to prevent overflow */
}

.card-item-endorsement {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
  margin: 10px;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in-out;
  overflow: hidden; /* Add this line to prevent overflow */
}

.card-item:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.card-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-icon {
  width: 50px;
  height: 50px;
  background-color: rgba(var(--v-theme-primary), 0.75);
  color: white;
  border-radius: 50%;
}

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}

.student-name {
  white-space: normal;
  text-wrap: break-word;
}

.card-endorsement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
}

.endorsement-title {
  white-space: normal;
  text-wrap: break-word;
}

.card-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
}

.name-placeholder {
  color: rgba(var(--v-theme-primary), 0.5);
}

.rating-chip {
  background-color: rgb(var(--v-theme-primary));
  color: white;
  border-radius: 5px;
}

.type-chip {
  background-color: rgb(var(--v-theme-accent));
  color: white;
  border-radius: 5px;
}

.path-text {
  padding-block: 5px;
}

.card-email {
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 10px;
  padding-bottom: 5px;
}

.card-owner {
  word-break: break-word;
  padding-top: 10px;
  padding-bottom: 5px;
}

.endorsement-details {
  padding-block: 5px;
}

.fab-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: var(--v-theme-primary);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .main-container-trial {
    height: 90vh;
  }

  .main-container-basic,
  .main-container-professional {
    height: 95vh;
  }
}

@media (max-width: 600px) {
  .navigation-button {
    margin-inline: 5px;
  }
  
  .avatar-icon {
    width: 40px;
    height: 40px;
  }

  .fab-button {
    bottom: 20px;
    right: 20px;
  }

  .card-container {
    padding-top: 20px;
    padding-bottom: 80px;
  }
}
</style>
