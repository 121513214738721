<!-- src/components/StudentInformation.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">
    <v-card class="card-container">
      <v-card-title class="title-text">Student Information</v-card-title>
      <v-card-text class="scrollable-content">
        <v-container class="pt-0 small-container">

          <!-- Description text -->
          <v-card-text class="student-subtext pt-0">
            Most fields are read-only and can only be updated by the student via their profile. However, you can directly update the 'Course Info' fields. Please remind students to keep their profiles updated to ensure accurate training records.
          </v-card-text>

          <!-- Basic Info -->
          <v-card-subtitle class="section-title">Basic Info</v-card-subtitle>
          <v-row dense>
            <v-col :cols="12" :md="6">
              <v-select
                readonly
                label="Email"
                variant="outlined"
                prepend-inner-icon="mdi-email"
                class="text-field"
                menu-icon=""
                hide-details
                :model-value="student.email"
              ></v-select>
            </v-col>
            <v-col :cols="12" :md="6">
              <v-select
                readonly
                label="Phone Number"
                variant="outlined"
                prepend-inner-icon="mdi-phone"
                class="text-field"
                menu-icon=""
                hide-details
                :model-value="student.phoneNumber"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                readonly
                label="Address"
                variant="outlined"
                prepend-inner-icon="mdi-map-marker"
                class="text-field"
                menu-icon=""
                hide-details
                :model-value="student.address"
              ></v-select>
            </v-col>
          </v-row>

          <!-- Course Info -->
          <v-card-subtitle class="section-title">Course Info</v-card-subtitle>
          <v-row dense>
            <v-col :cols="12" :md="6">
              <v-select
                label="Training Course"
                variant="outlined"
                prepend-inner-icon="mdi-school"
                class="text-field"
                item-color="primary"
                hide-details
                :items="this.trainingCourses"
                v-model="student.trainingCourse"
                @blur="handleTrainingCourseUpdate()"
              ></v-select>
            </v-col>
            <v-col :cols="12" :md="6">
              <v-select
                label="Training Path"
                variant="outlined"
                prepend-inner-icon="mdi-call-split"
                class="text-field"
                item-color="primary"
                hide-details
                :items="this.trainingPaths"
                v-model="student.trainingPath"
                @blur="handleTrainingPathUpdate()"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                variant="outlined"
                class="text-field"
                label="ACS Standard"
                item-color="primary"
                prepend-inner-icon="mdi-book"
                item-title="name"
                item-value="id"
                hide-details
                clearable
                :items="this.standardsOptions"
                v-model="student.acsStandard"
                @blur="handleStandardsUpdate()"
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item class="mb-1" v-bind="props" :subtitle="item.raw.faaKey"></v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <!-- Certifications & Ratings -->
          <v-card-subtitle class="section-title">Certifications & Ratings</v-card-subtitle>
          <v-row dense>
            <v-col cols="12">
              <v-select 
                readonly  
                label="Certificates"
                variant="outlined"
                prepend-inner-icon="mdi-certificate"
                menu-icon=""
                chips
                class="text-field"
                hide-details
                :model-value="student.certificates"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select 
                readonly  
                label="Ratings"
                variant="outlined"
                prepend-inner-icon="mdi-weather-partly-cloudy"
                menu-icon=""
                chips
                class="text-field"
                hide-details
                :model-value="student.ratings"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select 
                readonly  
                label="Endorsements"
                variant="outlined"
                prepend-inner-icon="mdi-pencil-plus"
                menu-icon=""
                chips
                class="text-field"
                hide-details
                :model-value="student.endorsements"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select 
                readonly  
                label="Aircraft Flown"
                variant="outlined"
                prepend-inner-icon="mdi-airplane"
                menu-icon=""
                chips
                class="text-field"
                hide-details
                :model-value="student.aircraft"
              ></v-select>
            </v-col>
            <v-col :cols="12" :md="6">
              <v-select 
                readonly  
                label="Medical"
                variant="outlined"
                prepend-inner-icon="mdi-medical-bag"
                menu-icon=""
                chips
                class="text-field"
                hide-details
                :model-value="student.medical"
              ></v-select>
            </v-col>
            <v-col :cols="12" :md="6">
              <v-select 
                readonly  
                label="Medical Expiration"
                variant="outlined"
                prepend-inner-icon="mdi-calendar-clock"
                menu-icon=""
                class="text-field"
                hide-details
                :model-value="student.medicalExpiration"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { 
  fetchAcsStandards,
  updateStudentTrainingPath, 
  updateStudentTrainingCourse,
  updateStudentStandard
} from '@/services/firebaseService';

export default {
  name: 'StudentInformation',
  emits: [
    'updateSuccessful', 
    'updateFailed'
  ],
  data() {
    return {
      dialog: false,
      student: null,
      instructorUid: null,
      trainingCourses: [
        "Sport Pilot",
        "Recreational Pilot",
        "Private Pilot",
        "Commercial Pilot",
        "Airline Transport Pilot",
        "Instrument",
        "Multi-Engine",
        "Helicopter",
        "Seaplane",
        "Certified Flight Instructor",
        "Instrument Instructor",
        "Multi-Engine Instructor",
        "Tailwheel Endorsement",
        "High Performance Endorsement",
        "High Altitude Endorsement",
        "Complex Endorsement",
        "Gyroplane Endorsement",
        "Discovery Flight",
        "Flight Review",
        "Instrument Proficiency Check"
      ],
      trainingPaths: [
        "Part 61",
        "Part 141",
        "Part 135",
        "Part 121"
      ],
      standards: [],
      standardsOptions: []
    };
  },
  created() {
    this.fetchStandards();
  },
  methods: {
    open(student, instructorUid) {
      this.dialog = true;
      this.student = student;
      if (student.medical === '') {
        this.student.medical = [];
      }
      this.instructorUid = instructorUid;
    },
    async fetchStandards() {
      this.isLoading = true;
      try {
        this.standards = await fetchAcsStandards();
        this.standardsOptions = this.standards.map(standard => ({
          id: standard.id,
          name: standard.name,
          faaKey: standard.faaKey
        }));
      } catch (error) {
        console.error("Error fetching standards:", error);
      }
      this.isLoading = false;
    },
    async handleTrainingCourseUpdate() {
      const updateStatus = await updateStudentTrainingCourse(
        this.instructorUid,
        this.student.id,
        this.student.trainingCourse
      );
      if (updateStatus) {
        this.$emit('updateSuccessful');
      } else {
        this.$emit('updateFailed');
      }
    },
    async handleTrainingPathUpdate() {
      const updateStatus = await updateStudentTrainingPath(
        this.instructorUid,
        this.student.id,
        this.student.trainingPath
      );
      if (updateStatus) {
        this.$emit('updateSuccessful');
      } else {
        this.$emit('updateFailed');
      }
    },
    async handleStandardsUpdate() {
      const updateStatus = await updateStudentStandard(
        this.instructorUid,
        this.student.id,
        this.student.acsStandard || null
      );
      if (updateStatus) {
        this.$emit('updateSuccessful');
      } else {
        this.$emit('updateFailed');
      }
    }
  }
};
</script>

<style scoped>
.dialog-class {
  width: 80vw;
}

.card-container {
  display: flex;
  flex-direction: column;
  height: 90vh;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.title-text {
  color: rgba(var(--v-theme-accent));
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.scrollable-content {
  height: 100%;
  margin-right: 10px;
  margin-block: 10px;
  overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-accent), 0.1);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-accent), 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-accent), 0.8);
}

.text-field {
  color: #91b9c9 !important;
  margin-block: 10px;
}

.student-subtext {
  color: rgba(var(--v-theme-accent));
  text-align: center;
  font-size: 14px;
  word-wrap: break-word;
  white-space: normal;
}

.section-title {
  color: rgb(var(--v-theme-primary));
  background-color: rgba(var(--v-theme-accent), 0.5);
  backdrop-filter: blur(10px);
  padding: 5px;
  border-radius: 5px;
  margin-block: 20px;
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 100vw;
  }

  .card-container {
    height: 80vh;
    margin-left: 0px;
  }

  .scrollable-content {
    margin-right: 0px;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 600px) {
  .small-container {
    padding-inline: 0px;
  }

  .dialog-class {
    width: 100vw;
  }

  .card-container {
    height: 90vh;
    margin-left: 0px;
  }
}
</style>
