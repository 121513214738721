<!-- src/components/NewStudent.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">
    <v-card class="card-container">
      <v-card-title class="title-text">Add New Student</v-card-title>
      <v-card-text class="card-text-area">
        Add a new student to your instructor dashboard by entering their email address. This will enable you to track their FlightSense activities and document lessons.
      </v-card-text>
      <v-card-text>
        <v-row no-gutters class="d-flex align-center justify-center">
          <v-col cols="12">
            <v-text-field
              variant="outlined"
              class="input-field"
              label="Student Email"
              prepend-inner-icon="mdi-email"
              v-model="this.studentEmail"
              :focused="emailError"
              :hint="emailErrorMessage"
              @blur="checkEmailExists"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              variant="outlined"
              class="input-field"
              label="Student Name"
              prepend-inner-icon="mdi-account"
              v-model="this.studentName"
              @blur="capitalizeName"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select 
              variant="outlined"
              class="input-field"
              label="Select Course"
              :items="trainingCourses"
              item-color="primary"
              prepend-inner-icon="mdi-school"
              v-model="this.selectedTrainingCourse"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select 
              variant="outlined"
              class="input-field"
              label="Select Training Path"
              :items="['Part 61', 'Part 141', 'Part 135', 'Part 121']"
              item-color="primary"
              prepend-inner-icon="mdi-call-split"
              v-model="this.selectedTrainingPath"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              variant="outlined"
              class="input-field"
              label="ACS Standard"
              :items="this.standardsOptions"
              item-color="primary"
              prepend-inner-icon="mdi-book"
              item-title="name"
              item-value="id"
              v-model="this.selectedStandard"
            >
            </v-select>
          </v-col>
          <v-row no-gutters class="email-switch">
            <v-col cols="8" justify="start">
              <v-card-text v-if="!this.emailExists">
                Send Email Invite
              </v-card-text>
              <v-card-text v-else>
                Student Account Exists
              </v-card-text>
            </v-col>
            <v-col cols="4" class="d-flex justify-end">
              <v-switch
                color="accent"
                hide-details
                class="px-5"
                :disabled="this.emailExists"
                v-model="sendEmail"
              ></v-switch>
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text @click="close">Cancel</v-btn>
        <v-btn color="accent" variant="flat" :loading="isLoading" :disabled="saveButtonDisabled" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebaseConfig';
import { 
  fetchAcsStandards, 
  checkIfEmailExists, 
  getStudentInfo, 
  addStudentToInstructor, 
  addTemporaryStudent 
} from '../services/firebaseService'
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'NewStudent',
  emits: [
    'studentAdded',
    'inviteSent',
    'inviteFailed'
  ],
  data() {
    return {
      dialog: false,
      isLoading: false,
      instructorUid: null,
      instructorName: '',
      trainingCourses: [
        "Sport Pilot",
        "Recreational Pilot",
        "Private Pilot",
        "Commercial Pilot",
        "Airline Transport Pilot",
        "Instrument",
        "Multi-Engine",
        "Helicopter",
        "Seaplane",
        "Certified Flight Instructor",
        "Instrument Instructor",
        "Multi-Engine Instructor",
        "Tailwheel Endorsement",
        "High Performance Endorsement",
        "High Altitude Endorsement",
        "Complex Endorsement",
        "Gyroplane Endorsement",
        "Discovery Flight",
        "Flight Review",
        "Instrument Proficiency Check"
      ],
      standards: [],
      standardsOptions: [],
      studentEmail: null,
      studentName: null,
      selectedTrainingCourse: null,
      selectedTrainingPath: null,
      selectedStandard: null,
      emailError: false,
      emailErrorMessage: '',
      emailExists: false,
      sendEmail: true,
      saveButtonDisabled: false
    };
  },
  created() {
    this.fetchStandards();
  },
  methods: {
    open(instructorUid, instructorName) {
      this.dialog = true;
      this.instructorUid = instructorUid;
      this.instructorName = instructorName;
    },
    async fetchStandards() {
      this.isLoading = true;
      try {
        this.standards = await fetchAcsStandards();
        this.standardsOptions = this.standards.map(standard => ({
          id: standard.id,
          name: standard.name
        }));
      } catch (error) {
        console.error("Error fetching standards:", error);
      }
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        const emailExists = await this.checkEmailExists();

        if (emailExists) {
          const studentInfo = await getStudentInfo(this.studentEmail);

          if (studentInfo) {
            await addStudentToInstructor(
              this.instructorUid, 
              studentInfo,
              this.selectedTrainingCourse,
              this.selectedTrainingPath,
              this.selectedStandard
            );

            this.$emit('studentAdded');
            this.close();
          } else {
            console.error('Failed to retrieve student information');
          }
        } else if (this.studentEmail) {
          const temporaryId = uuidv4();
          await addTemporaryStudent(
            this.instructorUid,
            this.studentEmail,
            this.studentName,
            this.selectedTrainingCourse,
            this.selectedTrainingPath,
            this.selectedStandard,
            temporaryId
          );

          if (this.sendEmail) {
            const sendInviteEmail = httpsCallable(functions, 'sendInviteEmail');
            try {
              await sendInviteEmail({
                email: this.studentEmail,
                studentName: this.studentName || "Aviator",
                instructorName: this.instructorName || 'Unknown Instructor'
              });
              this.$emit('inviteSent');
              this.close();
            } catch (error) {
              console.error('Error sending invite email:', error);
              this.$emit('inviteFailed');
            }
          } else {
            this.$emit('studentAdded');
            this.close();
          }
        }
      } catch (error) {
        console.error('An unexpected error occurred:', error);
      } finally {
        this.isLoading = false;
      }
    },
    resetForm() {
      this.studentEmail = null;
      this.studentName = null;
      this.selectedTrainingCourse = null;
      this.selectedTrainingPath = null;
      this.sendEmail = true;
      this.emailExists = false;
      this.saveButtonDisabled = false;
      this.emailError = false;
      this.emailErrorMessage = '';
    },
    close() {
      this.dialog = false;
      this.resetForm();
    },
    async checkEmailExists() {
      if (!this.studentEmail) {
        this.emailError = true;
        this.emailErrorMessage = 'Please enter an email address';
        return false;
      }
      try {
        const { emailExistsInUsers, emailExistsInActiveStudents } = await checkIfEmailExists(this.studentEmail, this.instructorUid);
        
        if (emailExistsInActiveStudents) {
          // If the email exists in the instructor's activeStudents collection
          this.emailExists = true;
          this.emailError = true;
          this.emailErrorMessage = 'This student is already in your dashboard';
          this.saveButtonDisabled = true;
          this.sendEmail = false;
          return false;
        } else if (emailExistsInUsers) {
          // If the email exists in the users collection but not in the activeStudents collection
          this.emailExists = true;
          this.emailError = false;
          this.emailErrorMessage = '';
          this.saveButtonDisabled = false;
          this.sendEmail = false;
          return true;
        } else {
          // If the email does not exist in either collection
          this.emailExists = false;
          this.emailError = false;
          this.emailErrorMessage = '';
          this.saveButtonDisabled = false;
          return false;
        }
      } catch (error) {
        console.error('Failed to check email:', error);
        this.emailError = true;
        this.emailErrorMessage = 'Error checking email. Please try again.';
        return false;
      }
    },
    capitalizeName() {
      if (this.studentName) {
        this.studentName = this.studentName.replace(/\b\w/g, char => char.toUpperCase());
      }
    }
  }
};
</script>

<style scoped>
.dialog-class {
  width: 50vw;
}

.card-container {
  display: flex;
  flex-direction: column;
  height: 90vh;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.title-text {
  color: rgba(var(--v-theme-accent));
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.card-text-area {
  color: rgba(var(--v-theme-accent));
  text-align: center;
  font-size: 14px;
  word-wrap: break-word;
  white-space: normal;
  padding-block: 0px !important;
}

.input-field {
  margin-inline: 30px;
  margin-block: 0px;
  color: rgba(var(--v-theme-accent));
}

.email-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: 30px;
  color: rgb(var(--v-theme-accent));
  background-color: rgba(var(--v-theme-accent), 0.1);
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .dialog-class {
   width: 70vw;
  }

  .card-container {
    height: 75vh;
    margin-left: 0px;
  }

  .scrollable-content {
    margin-right: 0px;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }

  .input-field {
    margin-inline: 0px;
  }

  .email-switch {
    margin-inline: 0px;
  }
}

@media (max-width: 600px) {
  .dialog-class {
    width: 100vw;
  }

  .card-container {
    height: 100vh;
    margin-left: 0px;
  }

  .input-field {
    margin-inline: 0px;
  }
}
</style>