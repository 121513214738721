// plugins/vuetify.js

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'

const myCustomLightTheme = {
  dark: false, // Indicate that this is a light theme
  colors: {
    primary: '#2b425c',   // Deep Blue
    secondary: '#d2917b', // Soft Peach
    accent: '#91b9c9',    // Light Blue
    info: '#517e9c',      // Medium Blue
    warning: '#e69500',   // Soft Orange
    error: '#c94e50',     // Muted Red
    success: '#4caf50',   // Friendly Green
    background: '#efeee5', // Pale Cream
  },
};

// Create and export the Vuetify instance
export default createVuetify({
  theme: {
    defaultTheme: 'myCustomLightTheme', // Set your custom theme as the default
    themes: {
      myCustomLightTheme, 
    },
  },
  defaults: {
    VAppBar: {
      elevation: 0,
    },
    global: {
      font: {
        fontFamily: 'Roboto, sans-serif',
      },
    },
  },
});
