// src/store/index.js

import { createStore } from 'vuex';
import authService from '../services/authService';
import { fetchUserProfile, checkUserSubscription, checkUserProducts, hasUserHadProfessionalSubscription } from '../services/firebaseService';
import { db } from "../../firebaseConfig";
import { doc, onSnapshot } from "firebase/firestore";

export default createStore({
  state: {
    user: {
      uid: null,
      email: '',
      displayName: '',
      photoURL: '',
      dateCreated: null,
      lastLogin: null,
      isPaidUser: false,
      product: '',
      isFreeTrial: false,
      trialDaysRemaining: 0,
      hasHadProfessionalSubscription: false
    },
    isLoadingUser: false,
    unsubscribeUserListener: null
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setLoading(state, isLoading) {
      state.isLoadingUser = isLoading;
    }
  },
  actions: {
    async fetchUser({ commit, state }) {
      commit('setLoading', true);
      const user = authService.state.user;
      if (user) {
        try {
          const userRef = doc(db, "users", user.uid);
    
          // Unsubscribe previous listener if exists
          if (state.unsubscribeUserListener) {
            state.unsubscribeUserListener();
          }
    
          const userProfile = await fetchUserProfile(user.uid);
          const isPaidUser = await checkUserSubscription(user.uid);
          const productData = await checkUserProducts(user.uid);
          const hasHadProfessionalSubscription = await hasUserHadProfessionalSubscription(user.uid);

          const initialUserData = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            dateCreated: userProfile.dateCreated,
            lastLogin: userProfile.lastLogin,
            isPaidUser: isPaidUser,
            product: productData.productName,
            isFreeTrial: productData.isFreeTrial,
            trialDaysRemaining: productData.trialDaysRemaining,
            subscriptionOverdue: productData.subscriptionOverdue,
            hasHadProfessionalSubscription: hasHadProfessionalSubscription
          };
    
          commit('setUser', initialUserData);
    
          const unsubscribe = onSnapshot(userRef, async (doc) => {
            if (doc.exists()) {
              const userData = doc.data();
  
              // Re-fetch subscription data on snapshot change
              const productData = await checkUserProducts(user.uid);
  
              commit('setUser', {
                ...state.user,
                dateCreated: userData.dateCreated ?? state.user.dateCreated,
                lastLogin: userData.lastLogin ?? state.user.lastLogin,
                isPaidUser: productData.isPaidUser ?? state.user.isPaidUser,
                product: productData.productName ?? state.user.product,
                isFreeTrial: productData.isFreeTrial ?? state.user.isFreeTrial,
                trialDaysRemaining: productData.trialDaysRemaining ?? state.user.trialDaysRemaining,
                subscriptionOverdue: productData.subscriptionOverdue ?? state.user.subscriptionOverdue,
                hasHadProfessionalSubscription: userData.hasHadProfessionalSubscription ?? state.user.hasHadProfessionalSubscription,
              });
            } else {
              console.log("No such user document!");
            }
          });
    
          // Store unsubscribe function to be called on sign out
          state.unsubscribeUserListener = unsubscribe;
          commit('setLoading', false);
        } catch ( error) {
          console.error('Error fetching user data:', error);
          commit('setLoading', false);
        }
      } else {
        console.log('No user logged in');
        commit('setLoading', false);
      }
    },
    signOut({ commit, state }) {
      if (state.unsubscribeUserListener) {
        state.unsubscribeUserListener();
      }
      authService.signOut().then(() => {
        commit('setUser', null); // Clear user in Vuex on sign out
      }).catch(error => {
        console.error('Sign Out Error:', error);
      });
    }
  },
  getters: {
    isAuthenticated(state) {
      return !!state.user;
    },
    user(state) {
      return state.user;
    },
    isLoadingUser(state) {
      return state.isLoadingUser;
    }
  }
});
