<!-- src/components/ChatHistory.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">
    <v-card class="card-container">
      <v-card-title class="title-text">Chat History</v-card-title>

      <!-- Loading indicator -->
      <v-container v-if="isLoading" class="fill-height d-flex align-center justify-center">
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              color="accent"
              size="50"
              width="2.5">
              <v-icon>mdi-airplane</v-icon>
            </v-progress-circular>
          </v-col>
        </v-row>
      </v-container>

      <!-- Chat history items -->
      <v-card-text v-if="!isLoading && chats" class="card-text-area">
        <div class="scrollable-content">
          <v-list style="background-color: transparent;" v-if="chats.length > 0">
            <v-list-item 
              v-for="(chat, index) in chats" 
              :key="index"
              :title="chat.firstMessage"
              :subtitle="formatDate(chat.threadCreated)"
              class="mb-4"
              base-color="accent"
              @click="selectChat(chat.threadUid)"
              ></v-list-item>
          </v-list>
          <div v-else class="no-chat-history">
            No chat history found.
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { analytics } from '../../firebaseConfig';
import { logEvent } from 'firebase/analytics';
import { getThreadSummaries } from '@/services/api';

export default {
  name: 'ChatHistory',
  data() {
    return {
      dialog: false,
      chats: [],
      isLoading: false
    };
  },
  methods: {
    open(userUid) {
      this.dialog = true;
      this.fetchChats(userUid);
    },
    async fetchChats(userUid) {
      try {
        this.isLoading = true;
        this.chats = await getThreadSummaries();
        logEvent(analytics, 'fetched_user_chat_history', { userId: userUid});
      } catch (error) {
        console.error("Error fetching chat history from service:", error);
        this.chats = [];
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(date) {
      if (!date) return '';
      return new Date(date).toLocaleDateString();
    },
    selectChat(threadId) {
      this.$emit('chatSelected', threadId);
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.dialog-class {
  width: 65vw;
}

.card-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.title-text {
  color: rgba(var(--v-theme-accent));
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.card-text-area {
  flex-grow: 1;
  overflow-y: auto;
}

.scrollable-content {
  height: 100%;
  overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-accent), 0.1);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-accent), 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-accent), 0.8);
}

.no-chat-history {
  text-align: center;
  padding: 20px;
  color: rgba(var(--v-theme-accent));
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 80vw;
  }

  .card-container {
    height: 70vh;
    margin-left: 0px;
  }

  .scrollable-content {
    margin-right: 0px;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 600px) {
  .dialog-class {
    width: 100vw;
  }

  .card-container {
    height: 90vh;
    margin-left: 0px;
  }
}
</style>
