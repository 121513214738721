<!-- src/components/CheckoutOverlay.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">
    <v-card class="card-container">
      <v-card-text class="scrollable-content">
          <v-row class="fill-height">
            <v-col :cols="12" :md="6">
              <v-card variant="flat" color="info" class="subscription-option">
                <v-card variant="flat" class="subscription-header">
                  <v-card-title class="subscription-title">Basic</v-card-title>
                  <v-card-text class="subscription-subtext original-price">$20/month</v-card-text>
                  <v-card-text class="subscription-subtext discounted-price">$10/month</v-card-text>
                  <v-badge 
                    content="Early Adopter Discount!" 
                    color="success" 
                    inline
                    class="discount-badge"
                  ></v-badge>
                  <v-card-text class="subscription-subtitle-basic rounded">Your Personal Aviation AI Assistant</v-card-text>
                </v-card>
                <v-card-text class="subscription-text">
                  <ul>
                    <li><strong>Instant Answers:</strong> Get accurate, real-time responses to your aviation questions anytime.</li>
                    <li><strong>Document Integration:</strong> Tailor AI responses with your uploaded aviation documents for more relevant insights.</li>
                    <li><strong>Instructor Feedback:</strong> Receive personalized feedback directly from your instructor to enhance your learning experience.</li>
                    <li><strong>Lifetime Record Access:</strong> Manage and access all your training records seamlessly throughout your aviation journey.</li>
                  </ul>
                </v-card-text>
                <v-btn class="selection-button-basic" variant="flat" @click="handleCheckout(basicProduct)">Subscribe</v-btn>
              </v-card>
            </v-col>
            <v-col :cols="12" :md="6">
                <v-card variant="flat" color="secondary" class="subscription-option">
                  <v-card variant="flat" class="subscription-header">
                  <v-card-title class="subscription-title">Professional</v-card-title>
                  <v-card-text class="subscription-subtext original-price">$30/month</v-card-text>
                  <v-card-text class="subscription-subtext discounted-price">$20/month</v-card-text>
                  <v-badge 
                    content="Early Adopter Discount!" 
                    color="success" 
                    inline
                    class="discount-badge"
                  ></v-badge>
                  <v-card-text class="subscription-subtitle-professional rounded">Streamline and Elevate Flight Instruction</v-card-text>
                </v-card>
                <v-card-text class="subscription-text">
                  <ul>
                    <li><strong>All Basic Features:</strong> Access everything in the Basic plan, including AI assistant, document integration, and training records.</li>
                    <li><strong>Real-Time Lesson Planning:</strong> Enhance your lesson planning with dynamic, real-time tools designed for in-flight and ground instruction.</li>
                    <li><strong>Performance Tracking:</strong> Track, assess, and optimize student performance with data-driven insights and metrics.</li>
                    <li><strong>Rapid Feedback:</strong> Deliver fast, personalized feedback to your students, helping them improve efficiently and effectively.</li>
                  </ul>
                </v-card-text>
                <v-card variant="flat" class="subscription-header d-flex">
                  <v-card-text class="free-text">FREE with at least 3 active students!</v-card-text>
                </v-card>
                <v-btn v-if="!hasHadProfessionalSubscription" class="selection-button-professional" variant="flat" @click="handleProfessionalTrialCheckout(professionalProduct)">Start 30-Day Trial</v-btn>
                <v-btn v-else class="selection-button-professional" variant="flat" @click="handleCheckout(professionalProduct)">Subscribe</v-btn>
              </v-card>
            </v-col>
          </v-row>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CheckoutOverlay',
  data() {
    return {
      dialog: false,
      hasHadProfessionalSubscription: false,
      basicProduct: process.env.VUE_APP_BASIC_SUBSCRIPTION_PRICE,
      professionalProduct: process.env.VUE_APP_PROFESSIONAL_SUBSCRIPTION_PRICE
    }
  },
  methods: {
    open(hasHadProfessionalSubscription) {
      this.dialog = true;
      this.hasHadProfessionalSubscription = hasHadProfessionalSubscription;
    },
    handleCheckout(priceId) {
      this.dialog = false;
      this.$emit('initiateCheckout', priceId);
    },
    handleProfessionalTrialCheckout(priceId) {
      this.dialog = false;
      this.$emit('initiateProfessionalTrialCheckout', priceId);
    }
  }
};
</script>

<style scoped>
.dialog-class {
  width: 70vw;
  max-width: 1250px;
}

.card-container {
  display: flex;
  flex-direction: column;
  height: 90vh;
  max-height: 800px;
  background-color: rgba(var(--v-theme-background), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.scrollable-content {
  height: 100%;
  margin-right: 10px;
  margin-top: 10px;
  overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-primary), 0.1);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-primary), 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-primary), 0.8);
}

.subscription-option {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.subscription-header {
  margin-inline: 20px;
  margin-top: 20px;
  background-color: rgba(var(--v-theme-background), 0.3);
}

.subscription-title {
  color: rgba(var(--v-theme-background));
  text-align: center;
  font-size: 24px;
}

.subscription-subtext {
  color: rgba(var(--v-theme-background));
  text-align: center;
}

.original-price {
  color: rgba(var(--v-theme-background), 0.5);
  text-decoration: line-through;
  padding-block: 0px;
}

.discounted-price {
  font-weight: 500;
  font-size: 20px;
  padding-block: 5px;
}

.discount-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 5px;
}

.subscription-subtitle-basic {
  color: rgba(var(--v-theme-background));
  background-color: rgb(var(--v-theme-info));
  text-align: center;
  margin: 10px;
  font-weight: 500;
}

.subscription-text {
  color: rgba(var(--v-theme-background));
  margin-top: 5px;
}

.selection-button-basic {
  margin: 20px;
  background-color: rgba(var(--v-theme-background));
  color: rgba(var(--v-theme-info));
}

.subscription-subtitle-professional {
  color: rgba(var(--v-theme-background));
  background-color: rgb(var(--v-theme-secondary));
  text-align: center;
  margin: 10px;
  font-weight: 500;
}

.selection-button-professional {
  margin: 20px;
  background-color: rgba(var(--v-theme-background));
  color: rgba(var(--v-theme-secondary));
}

.free-text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(var(--v-theme-background));
  font-weight: 500;
  padding-left: 8px;
}

ul {
  padding-left: 20px;
}

li {
  margin-block: 5px;
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 90vw;
  }

  .card-container {
    height: 80vh;
    margin-left: 0px;
  }

  .scrollable-content {
    margin-right: 0px;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }

  .free-text {
    color: rgba(var(--v-theme-background));
    font-weight: 500;
    padding-left: 16px;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .dialog-class {
    width: 100vw;
  }

  .card-container {
    height: 90vh;
    margin-left: 0px;
  }
}
</style>
