<!-- src/pages/LandingPage.vue -->

<template>
  <v-app-bar class="app-bar" density="compact">
    <v-app-bar-title>
      <a href="https://www.flightsense.ai" target="_blank" rel="noopener noreferrer">
        <v-img
          src="@/assets/full-logo.png"
          max-height="50px"
          contain
        ></v-img>
      </a>
    </v-app-bar-title>
    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
  </v-app-bar>

  <v-parallax :src="backgroundImage" class="main-container">
    <v-container class="my-10 d-flex align-center justify-center">
      <v-card flat class="tagline-card">
        <v-row no-gutters class="tagline-container">
          <v-fade-transition :duration="500" mode="out-in" appear>
            <v-card-title :key="currentMessageIndex" class="dynamic-message">{{ messages[currentMessageIndex] }}</v-card-title>
        </v-fade-transition>
        </v-row>
        <v-row no-gutters class="tagline-container mb-5">
          <v-card-title class="dynamic-message">with <strong>FlightSense</strong></v-card-title>
        </v-row>
      </v-card>
    </v-container>

    <v-row class="d-flex align-center justify-center">
      <v-col cols="12" class="d-flex justify-center">

        <!-- Card element -->
        <v-sheet class="login-card rounded" elevation="0">
          <v-row class="fill-height align-center justify-center">
            <v-col cols="12" class="justify-center">
              <div v-if="showEmailLogin">
                <v-row class="align-center justify-center mx-6 mt-10">
                  <v-text-field 
                    v-model="userEmail"
                    variant="outlined" 
                    density="comfortable"
                    type="email"
                    class="login-field"
                    label="Email address"
                    prepend-inner-icon="mdi-account"
                    autocomplete="email"
                    hide-details="true"
                  ></v-text-field>
                </v-row>
                <v-row class="justify-center mx-4 mt-10">
                  <v-card-actions>
                    <v-btn variant="tonal" class="login-button" @click="sendSignInLink">Send Email</v-btn>
                  </v-card-actions>
                </v-row>
                <v-row class="justify-center mx-4 mt-0">
                  <v-card-actions>
                    <v-btn variant="plain" density="compact" class="login-button" @click="toggleEmailLogin">Cancel</v-btn>
                  </v-card-actions>
                </v-row>
              </div>

              <!-- Login provider elements -->
              <div v-else>
                <v-row class="justify-center ma-4 pt-6">
                  <v-btn variant="tonal" class="login-button" prepend-icon="mdi-email" @click="toggleEmailLogin">
                    Email Login
                  </v-btn>
                </v-row>
                <v-row class="justify-center ma-4">
                  <v-btn variant="tonal" class="login-button" prepend-icon="mdi-google" @click="googleLogin">
                    Google Login
                  </v-btn>
                </v-row>
                <v-row class="justify-center ma-4">
                  <v-tooltip bottom text="Coming soon">
                    <template v-slot:activator="{ props }">
                      <v-btn variant="tonal" class="login-button" prepend-icon="mdi-apple" v-bind="props">
                        Apple Login
                      </v-btn>
                    </template>
                  </v-tooltip>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-sheet>

        <!-- Snackbar notification -->
        <SnackbarNotification :message="snackbar.message" :color="snackbar.color" />

      </v-col>
    </v-row>
  </v-parallax>

  <v-footer app height="100px" class="footer-class">
    <v-row dense height="80px" class="align-end justify-center mt-4">
      <v-btn density="comfortable" variant="plain" class="terms-button" @click="openTermsOfService">
        Terms of Service
      </v-btn>
      <v-card-text class="contact-text mb-0">
        info@flightsense.ai
      </v-card-text>
    </v-row>
  </v-footer>
</template>

<script>
import { 
  auth,
  GoogleAuthProvider, 
  signInWithPopup, 
  sendSignInLinkToEmail, 
  isSignInWithEmailLink, 
  signInWithEmailLink 
} from '../../firebaseConfig';
import authService from '@/services/authService';
import { createUserProfile } from '../services/firebaseService';
import { Sentry } from '@/plugins/sentry.js';
import { analytics } from '../../firebaseConfig';
import { logEvent } from 'firebase/analytics';
import SnackbarNotification from '../components/SnackbarNotification.vue';

export default {
  name: 'LandingPage',
  components: {
    SnackbarNotification
  },
  data () {
    return {
      backgroundImage: require('@/assets/landing-page-background.png'),
      logo: require('@/assets/logo.png'),
      snackbar: {
        message: '',
        color: ''
      },
      showEmailLogin: false,
      showPassword: false,
      userEmail: '',
      messages: [
        'Unlock your potential',
        'Break down regulations',
        'Master stalls',
        'Craft lesson plans',
        'Receive precise feedback',
        'Demystify lapse rates',
        'Grasp center of gravity',
        'Ace your checkride',
        'Understand hypoxia',
        'Navigate complex airspace',
        'Figure out weather patterns',
        'Improve radio communication',
        'Boost situational awareness',
        'Practice emergency procedures',
        'Hone navigation skills',
        'Decode NOTAMs',
        'Perfect landing techniques',
        'Evaluate flight maneuvers',
        'Sharpen decision-making',
        'Explore aircraft systems',
        'Evaluate flight performance',
        'Ace oral exams',
        'Analyze student progress',
        'Clarify aviation theories',
        'Streamline pre-flight checks',
        'Prepare for written exams'
      ],
      currentMessageIndex: 0,
      messageInterval: null
    }
  },
  methods: {
    async googleLogin() {
      const provider = new GoogleAuthProvider();

      try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        await createUserProfile(user);
        logEvent(analytics, 'user_signed_in_google', { userId: user.uid });
        this.$router.push('/chat');
      } catch (error) {
        Sentry.captureMessage("Google login failed");
        Sentry.captureException(error);
        console.error('Google login Failed:', error);
        let message = 'Login failed. Please try again.';
        switch (error.code) {
          case 'auth/popup-closed-by-user':
            message = 'The login popup was closed before completing the sign in';
            break;
          case 'auth/network-request-failed':
            message = 'Network error. Check your connection and try again.';
            break;
        }
        this.snackbar.message = message;
        this.snackbar.color = 'error';
      }
    },
    toggleEmailLogin() {
      this.showEmailLogin = !this.showEmailLogin;
    },
    sendSignInLink() {
      const actionCodeSettings = {
        url: process.env.VUE_APP_BASE_URL,
        handleCodeInApp: true,
      };

      sendSignInLinkToEmail(auth, this.userEmail, actionCodeSettings)
        .then(() => {
          window.localStorage.setItem('emailForSignIn', this.userEmail);
          this.snackbar.message = 'Sign-in link sent to your email';
          this.snackbar.color = 'success';
        })
        .catch((error) => {
          Sentry.captureMessage("Error sending sign-in email link");
          Sentry.captureException(error);
          console.error('Error sending sign-in email link:', error);
          let message = 'Error sending sign-in link. Please try again.';
          switch (error.code) {
            case 'auth/invalid-email':
              message = 'Invalid email. Please check and try again.';
              break;
            case 'auth/network-request-failed':
              message = 'Network error. Check your connection and try again.';
              break;
          }
          this.snackbar.message = message;
          this.snackbar.color = 'error';
        });
    },
    async completeSignIn() {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          email = window.prompt('Please provide your email for confirmation');
        }

        try {
          const result = await signInWithEmailLink(auth, email, window.location.href);
          const user = result.user;
          await createUserProfile(user);
          window.localStorage.removeItem('emailForSignIn');
          logEvent(analytics, 'user_signed_in_email', { userId: user.uid });
          this.$router.push('/chat');
        } catch (error) {
          Sentry.captureMessage("Error completing sign-in");
          Sentry.captureException(error);
          console.error('Error completing sign-in:', error);
          this.snackbar.message = 'Error completing sign-in. Please try again.';
          this.snackbar.color = 'error';
        }
      }
    },
    openTermsOfService() {
      window.open('https://drive.google.com/file/d/1NsFhvRi4vrxGBe6_NpwOsbr42mVa9R2X/view?usp=sharing', '_blank');
    },
    updateMessage() {
      let randomIndex = Math.floor(Math.random() * this.messages.length);
      while(randomIndex === this.currentMessageIndex) {
        randomIndex = Math.floor(Math.random() * this.messages.length);
      }
      this.currentMessageIndex = randomIndex;
    }
  },
  mounted() {
    this.messageInterval = setInterval(() => {this.updateMessage();}, 5000);
  },
  beforeUnmount() {
    clearInterval(this.messageInterval);
  },
  created() {
    if (!authService.isAuthenticated()) {
      // User is not signed in, check if it's an email link sign-in
      this.completeSignIn();
    }
  },
  beforeRouteEnter(to, from, next) {
    const isAuthenticated = authService.isAuthenticated();
    if (isAuthenticated) {
      // If user is already authenticated, redirect to ChatPage
      next('/chat');
    } else {
      // If user is not authenticated, proceed with rendering the LandingPage
      next();
    }
  }
}
</script>

<style scoped>
.app-bar {
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
}

.main-container {
  height: 100%;
}

.footer-class {
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
}

.tagline-card {
  background-color: rgba(200, 200, 200, 0.2);
  backdrop-filter: blur(10px);
  width: 1000px;
}

.tagline-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.dynamic-message {
  font-size: 40px;
  font-weight: 400;
  color: rgb(var(--v-theme-primary));
  text-align: center;
}

.login-card {
  height: 230px;
  width: 300px;
  background-color: rgba(var(--v-theme-primary), 0.5);
  backdrop-filter: blur(10px);
}

.login-button {
  color: white;
  min-width: 250px;
}

.logo-image {
  height: 64px;
  margin-top: 20px;
}

.terms-button {
  color: rgb(var(--v-theme-accent));
  font-weight: normal;
}

.contact-text {
  color: rgb(var(--v-theme-accent));
  font-weight: light;
  font-size: 12px;
  text-align: center;
  width: 100%;
}

.login-field {
  color: white;  
}

.email-text {
  color: rgb(var(--v-theme-accent));
  font-weight: normal;
  text-align: center;
  margin-bottom: 0;
}

@media (max-width: 600px) {
  .tagline-container {
    height: 40px;
  }

  .dynamic-message {
    font-size: 25px;
    margin-block: 5px;
  }
}
</style>
