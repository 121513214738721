// src/services/authService.js

import { reactive, readonly } from 'vue';
import { db, auth } from '/firebaseConfig';
import { onAuthStateChanged, signOut as firebaseSignOut, setPersistence, browserLocalPersistence } from 'firebase/auth';
import store from '../store';
import { Sentry } from '@/plugins/sentry.js';
import { 
  doc, 
  getDoc, 
  Timestamp,
  updateDoc,
} from "firebase/firestore";

const state = reactive({
  user: null
});

// eslint-disable-next-line no-unused-vars
async function recordUserLogin(userUid) {
  const userRef = doc(db, "users", userUid);
  const now = Timestamp.now();
  
  try {
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      await updateDoc(userRef, { lastLogin: now });
    }
  } catch (error) {
    Sentry.captureMessage("Error recording user login");
    Sentry.captureException(error);
    console.error("Error recording user login:", error);
  }
}

setPersistence(auth, browserLocalPersistence);

onAuthStateChanged(auth, async (user) => {
  state.user = user;
  store.commit('setUser', user);
  if(user) await recordUserLogin(user.uid);
});


const signOut = async () => {
  try {
    await firebaseSignOut(auth);
    window.localStorage.removeItem('emailForSignIn');
  } catch (error) {
    Sentry.captureMessage("Error signing out");
    Sentry.captureException(error);
    console.error('Error signing out:', error);
    throw error;
  }
};

export default {
  state: readonly(state),
  isAuthenticated() {
    return !!state.user;
  },
  signOut
};
