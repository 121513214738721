// src/main.js

import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import { loadFonts } from './plugins/webfontloader';
import { initializeSentry } from './plugins/sentry';

loadFonts();

const app = createApp(App);

initializeSentry(app);

app.use(vuetify)
  .use(router)
  .use(store)
  .mount('#app');
