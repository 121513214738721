<!-- src/components/ChatNavigationContent.vue -->

<template>
  <div class="drawer-content">
    <!-- Header -->
    <v-list bg-color="transparent">
      <v-list-item
        :prepend-avatar="userPhoto"
        :title="userName ? userName : 'FlightSense'"
        :subtitle="truncatedUserEmail"
        class="header-content"
      ></v-list-item>
    </v-list>

    <v-divider></v-divider>

    <!-- Chat Buttons(s) -->
    <v-list bg-color="transparent">
      <v-list-item
        prepend-icon="mdi-creation"
        title="New Chat"
        variant="flat"
        base-color="accent"
        rounded="true"
        class="reset-item"
        @click="resetChat"
      >
      </v-list-item>
      <v-list-item
        prepend-icon="mdi-history"
        title="Chat History"
        rounded="true"
        class="list-item"
        @click="openChatHistory"
      >
      </v-list-item>
      <v-list-item
        prepend-icon="mdi-file-upload"
        title="Custom Files"
        rounded="true"
        class="list-item"
        @click="openCustomFiles"
      >
      </v-list-item>
      <v-list-item
        v-if="flights.length > 0"
        title="Lesson History"
        rounded="true"
        class="list-item"
        @click="openLessonHistory"
      >
        <template v-slot:prepend>
          <v-badge dot color="error" v-if="newLessonsAvailable">
            <v-icon>mdi-airplane</v-icon>
          </v-badge>
          <v-icon v-else>mdi-airplane</v-icon>
        </template>
      </v-list-item>
    </v-list>

    <v-spacer></v-spacer>

    <v-list bg-color="transparent">
      <v-list-item
        prepend-icon="mdi-information-outline"
        variant="outlined"
        title="Assistant Information"
        base-color="accent"
        rounded="true"
        class="list-item"
        @click="openAssistantInfo"
      >
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <!-- Footer -->
    <v-list bg-color="transparent">
      <v-list-item
        v-if="isPaidUser"
        prepend-icon="mdi-account-cash"
        title="Billing"
        rounded="true"
        class="list-item-footer"
        @click="openBillingPortal"
      >
      </v-list-item>
      <v-list-item
        prepend-icon="mdi-account-circle"
        title="User Profile"
        rounded="true"
        class="list-item-footer"
        @click="openUserProfile"
      >
      </v-list-item>
      <v-list-item
        prepend-icon="mdi-logout"
        title="Logout"
        rounded="true"
        class="list-item-footer"
        @click="signOut"
      >
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    userPhoto: String,
    userName: String,
    truncatedUserEmail: String,
    flights: Object,
    newLessonsAvailable: Boolean,
    isPaidUser: Boolean
  },
  emits: [
    'reset-chat',
    'open-chat-history',
    'open-custom-files',
    'open-flight-lessons',
    'open-assistant-info',
    'open-billing-portal',
    'open-user-profile',
    'sign-out'
],
  methods: {
    resetChat() {
      this.$emit('reset-chat');
    },
    openChatHistory() {
      this.$emit('open-chat-history');
    },
    openCustomFiles() {
      this.$emit('open-custom-files');
    },
    openLessonHistory() {
      this.$emit('open-flight-lessons');
    },
    openAssistantInfo() {
      this.$emit('open-assistant-info');
    },
    openBillingPortal() {
      this.$emit('open-billing-portal');
    },
    openUserProfile() {
      this.$emit('open-user-profile');
    },
    signOut() {
      this.$emit('sign-out');
    },
  },
};
</script>

<style scoped>
.drawer-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-content {
  color: rgb(var(--v-theme-accent));
  margin: 4px;
}

.list-item {
  color: rgb(var(--v-theme-accent));
  margin-inline: 4px;
  margin-block: 4px;
  border-radius: 10px;
}

.list-item-footer {
  color: rgb(var(--v-theme-accent));
  margin-inline: 4px;
  border-radius: 10px;
}

.reset-item {
  background-color: rgb(var(--v-theme-accent));
  margin-inline: 4px;
  margin-block: 4px;
  border-radius: 10px;
}
</style>
