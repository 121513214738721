<!-- src/components/EditLesson.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">
    <v-card class="card-container">
      <v-card-title class="title-text">Edit Lesson</v-card-title>

      <!-- Lesson inputs -->
      <v-card-text class="scrollable-content">
        <v-date-input
          v-model="selectedDate"
          label="Date"
          prepend-inner-icon="mdi-calendar"
          prepend-icon=""
          variant="outlined"
          color="primary"
          base-color="accent"
          :min="minDate"
          :max="maxDate"
          dense
          class="text-field"
        ></v-date-input>
        <v-textarea
          variant="outlined"
          class="text-field"
          label="Lesson Notes"
          no-resize
          auto-grow
          v-model="lessonNotes"
        >
        </v-textarea>
      </v-card-text>

      <v-row no-gutters class="debrief-switch">
        <v-col cols="8" justify="start">
          <v-card-text>
            Regenerate AI Lesson Debrief
          </v-card-text>
        </v-col>
        <v-col cols="4" class="d-flex justify-end">
          <v-switch
            color="accent"
            hide-details
            class="px-5"
            v-model="regenerateDebrief"
          ></v-switch>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text @click="dialog = false">Cancel</v-btn>
        <v-btn color="accent" variant="flat" @click="saveLesson" :loading="saveLoading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { updateFlightLesson } from '@/services/firebaseService';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebaseConfig';
import { VDateInput } from 'vuetify/labs/VDateInput'

export default {
  name: 'EditLesson',
  components: {
    VDateInput
  },
  emits: ['flightLessonEdited', 'flightLessonError'],
  data() {
    return {
      dialog: false,
      saveLoading: false,
      // Component data
      instructorUid: null,
      studentUid: null,
      lesson: null,
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(),
      // User inputs
      selectedDate: null,
      lessonNotes: null,
      regenerateDebrief: false
    };
  },
  methods: {

    // UI NAVIGATION METHODS //

    open(studentUid, instructorUid, lesson) {
      this.studentUid = studentUid;
      this.instructorUid = instructorUid;
      this.lesson = lesson;
      this.selectedDate = new Date(lesson.timestamp.seconds * 1000);
      this.lessonNotes = lesson.lessonNotes;
      this.dialog = true;
    },
    async saveLesson() {
      this.saveLoading = true;

      let flightLesson = {
        instructor: this.instructorUid,
        timestamp: new Date(this.selectedDate),
        lessonNotes: this.lessonNotes || null,
      };

      try {
        if (this.regenerateDebrief) {
          this.generatingResponse = true;
          const generateLessonDebrief = httpsCallable(functions, 'generateLessonDebrief');
            const { data } = await generateLessonDebrief({ 
            message: this.lessonNotes,
          });
          const { debrief, debriefSummary } = data;
          
          // Update flightLesson with the new debrief and summary
          flightLesson.lessonDebrief = debrief;
          flightLesson.debriefSummary = debriefSummary;
        }

        await updateFlightLesson(this.studentUid, flightLesson, this.lesson.id);
        this.$emit('flightLessonEdited');

        // Reset fields and state
        this.saveLoading = false;
        this.regenerateDebrief = false;
        this.dialog = false;
      } catch (error) {
        this.saveLoading = false;
        this.regenerateDebrief = false;
        console.error("Failed to edit flight lesson:", error);
        this.$emit('flightLessonError');
      }
    },
  }
};
</script>

<style scoped>
.dialog-class {
  width: 60vw;
}

.card-container {
  display: flex;
  flex-direction: column;
  min-height: 350px;
  max-height: 80vh;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.title-text {
  color: rgba(var(--v-theme-accent));
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.text-field {
  color: #91b9c9 !important;
  margin-block: 4px;
}

.date-picker {
  color: white;
  background-color: rgb(var(--v-theme-primary));
}

:deep(.v-date-picker-month__day--selected .v-btn) {
    background-color: rgb(var(--v-theme-accent)) !important;
    color: white;
}

:deep(.v-field__field) {
  color: rgba(var(--v-theme-accent)) !important;
}

:deep(.v-field__prepend-inner) {
  color: rgba(var(--v-theme-accent)) !important;
}

:deep(.text-field .v-btn__content) {
  color: rgba(var(--v-theme-accent)) !important;
}

.scrollable-content {
  max-height: 40vh;
  margin-right: 10px;
  margin-block: 10px;
  overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-primary), 0.1);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-accent), 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-accent), 0.8);
}

.debrief-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: 30px;
  margin-block: 10px;
  color: rgb(var(--v-theme-accent));
  background-color: rgba(var(--v-theme-accent), 0.1);
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 100vw;
  }

  .card-container {
    min-height: 350px;
    max-height: 100vh;
    margin-left: 0px;
  }

  .scrollable-content {
    margin-right: 0px;
    max-height: 60vh;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }

  .debrief-switch {
    margin-inline: 20px;
  }
}
</style>
