<!-- src/components/AddLesson.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">
    <v-card class="card-container">
      <v-card-title class="title-text">Add Lesson</v-card-title>

      <!-- Lesson inputs -->
      <v-card-text class="scrollable-content">
        <v-date-input
          v-model="selectedDate"
          label="Date"
          prepend-inner-icon="mdi-calendar"
          prepend-icon=""
          variant="outlined"
          color="primary"
          base-color="accent"
          :min="minDate"
          :max="maxDate"
          dense
          class="text-field"
        ></v-date-input>
        <v-textarea
          v-if="!generatingResponse"
          variant="outlined"
          class="text-field"
          label="Lesson Notes"
          no-resize
          auto-grow
          v-model="lessonNotes"
        >
        </v-textarea>

        <v-row dense v-else>
          <v-col cols="12" class="justify-center">
            <v-img src="@/assets/orb-animation.png" class="breathing-image" />
          </v-col>
          <v-col colse="12">
            <v-card-text class="processing-text">Please wait. Amelia is processing your lesson notes...</v-card-text>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text @click="close">Cancel</v-btn>
        <v-btn color="accent" variant="flat" @click="saveLesson" :loading="saveLoading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { recordFlightLesson } from '@/services/firebaseService';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebaseConfig';
import { VDateInput } from 'vuetify/labs/VDateInput'
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'AddLesson',
  components: {
    VDateInput
  },
  emits: ['flightLessonAdded', 'flightLessonError'],
  data() {
    return {
      dialog: false,
      saveLoading: false,
      generatingResponse: false, 
      // Component data
      instructorUid: null,
      assistantId: null,
      student: null,
      studentUid: null,
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(),
      // User inputs
      selectedDate: new Date(),
      lessonNotes: null
    };
  },
  methods: {

    // UI NAVIGATION METHODS //

    open(student, instructorUid, assistantId) { 
      this.student = student;
      this.studentUid = student.id;
      this.instructorUid = instructorUid;
      this.assistantId = assistantId;
      this.dialog = true;
    },
    close() {
      this.selectedDate = new Date();
      this.lessonNotes = null;
      this.dialog = false;
    },
    async saveLesson() {
      const lessonId = uuidv4();
      this.saveLoading = true;
      this.generatingResponse = true;

      const generateLessonDebrief = httpsCallable(functions, 'generateLessonDebrief');
      
      try {
        const { data } = await generateLessonDebrief({ 
          message: this.lessonNotes,
          assistantId: this.assistantId
        });
        const { debrief, debriefSummary } = data;

        const flightLesson = {
          instructor: this.instructorUid,
          timestamp: new Date(this.selectedDate),
          lessonNotes: this.lessonNotes,
          lessonDebrief: debrief,
          debriefSummary: debriefSummary
        };

        await recordFlightLesson(this.studentUid, lessonId, flightLesson);
        this.$emit('flightLessonAdded');
        
        // Reset fields and state
        this.selectedDate = new Date();
        this.lessonNotes = null;
        this.generatingResponse = false;
        this.saveLoading = false;
        this.dialog = false;
      } catch (error) {
        this.saveLoading = false;
        this.generatingResponse = false;
        console.error("Failed to record flight lesson:", error);
        this.$emit('flightLessonError');
      }
    }
  }
};
</script>

<style scoped>
.dialog-class {
  width: 60vw;
}

.card-container {
  display: flex;
  flex-direction: column;
  min-height: 350px;
  max-height: 80vh;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.title-text {
  color: rgba(var(--v-theme-accent));
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.text-field {
  color: #91b9c9 !important;
  margin-block: 4px;
}

.date-picker {
  color: white;
  background-color: rgb(var(--v-theme-primary));
}

:deep(.v-date-picker-month__day--selected .v-btn) {
    background-color: rgb(var(--v-theme-accent)) !important;
    color: white;
}

:deep(.v-field__field) {
  color: rgba(var(--v-theme-accent)) !important;
}

:deep(.v-field__prepend-inner) {
  color: rgba(var(--v-theme-accent)) !important;
}

:deep(.text-field .v-btn__content) {
  color: rgba(var(--v-theme-accent)) !important;
}

.scrollable-content {
  height: 100%;
  margin-right: 10px;
  margin-block: 10px;
  overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-primary), 0.1);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-accent), 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-accent), 0.8);
}

.processing-text {
  text-align: center;
  color: rgba(var(--v-theme-accent)) !important;
  font-weight: 500;
}

.breathing-image {
  height: 25vh;
  animation: breathingAnimation 1.5s ease-in-out infinite;
}

@keyframes breathingAnimation {
  0%, 100% {
    transform: scale(0.85);
  }
  50% {
    transform: scale(1);
  }
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 100vw;
  }

  .card-container {
    min-height: 350px;
    max-height: 100vh;
    margin-left: 0px;
  }

  .scrollable-content {
    margin-right: 0px;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }
}
</style>
