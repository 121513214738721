<!-- src/components/UserProfile.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">
    <v-card class="card-container">
      <v-card-title class="title-text">User Profile</v-card-title>

      <v-card-text class="scrollable-content">
        <v-container class="pt-0 small-container">

          <!-- Explanation Text -->
          <v-row dense class="mb-10">
            <v-col cols="12">
              <v-alert 
                color="primary" 
                icon="mdi-alert"
                text="Providing accurate and up-to-date information in your user profile is crucial for your flight instructor."
              >
              </v-alert>
            </v-col>
          </v-row>

          <!-- Basic info -->
          <v-card-subtitle class="section-title">Basic Info</v-card-subtitle>
          <v-row dense>
            <v-col :cols="12" :md="6">
              <v-text-field 
                v-model="userInfo.name"
                class="text-field"
                label="Full Name" 
                variant="outlined"
                prepend-inner-icon="mdi-account"
                @Input="formatName"
              ></v-text-field>
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field 
                v-model="userInfo.birthday"
                class="text-field"
                label="Date of Birth" 
                hint="Please use the following format: MM/DD/YYYY"
                variant="outlined"
                prepend-inner-icon="mdi-calendar-star"
                @input="formatBirthday"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col :cols="12" :md="6">
              <v-text-field 
                v-model="userInfo.email"
                class="text-field" 
                label="Email Address" 
                variant="outlined"
                prepend-inner-icon="mdi-email"
                :readonly="userInfo.email !== ''"
                :hint="userInfo.email !== '' ? 'This field is read-only' : ''"
              ></v-text-field>
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field 
                v-model="userInfo.phone"
                class="text-field" 
                label="Phone Number" 
                variant="outlined"
                prepend-inner-icon="mdi-numeric-positive-1"
                @blur="formatPhoneNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field 
                v-model="userInfo.address"
                class="text-field" 
                label="Address" 
                variant="outlined"
                prepend-inner-icon="mdi-map-marker"
                @blur="formatAddress"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Certifications & Ratings -->
          <v-card-subtitle class="section-title">Certifications & Ratings</v-card-subtitle>
          <v-row dense>
            <v-col cols="12">
              <v-select
                v-model="userInfo.certificates"
                class="text-field"
                label="Certificates"
                prepend-inner-icon="mdi-certificate"
                item-color="primary"
                :items="this.certificates"
                multiple
                chips
                clearable
                variant="outlined"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="userInfo.ratings"
                class="text-field"
                label="Ratings"
                prepend-inner-icon="mdi-weather-partly-cloudy"
                item-color="primary"
                :items="this.ratings"
                multiple
                chips
                clearable
                variant="outlined"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="userInfo.endorsements"
                class="text-field"
                label="Endorsements"
                prepend-inner-icon="mdi-pencil-plus"
                item-color="primary"
                :items="this.endorsements"
                multiple
                chips
                clearable
                variant="outlined"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="userInfo.aircraft"
                class="text-field"
                label="Aircraft Flown"
                prepend-inner-icon="mdi-airplane"
                item-color="primary"
                :items="this.aircraft"
                hint="Select from the dropdown or start typing to enter your own aircraft"
                persistent-hint
                multiple
                chips
                clearable
                variant="outlined"
                @update:modelValue="formatAircraft"
              ></v-combobox>
            </v-col>
            <v-col :cols="12" :md="6">
              <v-select
                v-model="userInfo.medical"
                class="text-field"
                label="Medical"
                prepend-inner-icon="mdi-medical-bag"
                item-color="primary"
                :items="this.medical"
                clearable
                variant="outlined"
              ></v-select>
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field 
                v-model="userInfo.medicalExpiration"
                class="text-field"
                label="Medical Expiration" 
                hint="Please use the following format: MM/DD/YYYY"
                variant="outlined"
                prepend-inner-icon="mdi-calendar-clock"
                @input="formatMedicalExpiration"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text @click="dialog = false">Cancel</v-btn>
        <v-btn color="accent" variant="flat" @click="saveProfile">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchUserProfile, updateUserProfile } from "@/services/firebaseService";

export default {
  name: 'UserProfile',
  emits: ['profileUpdated'],
  data() {
    return {
      dialog: false,
      userUid: null,
      userInfo: {
        name: null,
        email: null,
        phone: null,
        birthday: null,
        address: null,
        certificates: null,
        ratings: null,
        endorsements: null,
        aircraft: null,
        medical: null,
        medicalExpiration: null
      },
      // Arrays for data available to v-selects
      certificates: [
        "Student Pilot",
        "Sport Pilot",
        "Recreational Pilot",
        "Private Pilot",
        "Commercial Pilot",
        "Airline Transport Pilot (ATP)",
        "Certified Flight Instructor (CFI)"
      ],
      ratings: [
        "Airplane",
        "Helicopter",
        "Instrument",
        "Multi-Engine",
        "Seaplane",
        "Instrument Instructor (CFII)",
        "Multi-Engine Instructor (MEI)"
      ],
      endorsements: [
        "Tailwheel",
        "High Performance",
        "High Altitude",
        "Complex"
      ],
      aircraft: [
        'Beechcraft Bonanza',
        'Beechcraft Baron',
        'Beechcraft Musketeer',
        'Beechcraft Sierra',
        'Beechcraft Skipper',
        'Beechcraft Sundowner',
        'Cessna 120/140',
        'Cessna 150/152',
        'Cessna 170',
        'Cessna 172',
        'Cessna 175',
        'Cessna 177 Cardinal',
        'Cessna 180',
        'Cessna 182 Skylane',
        'Cessna 185 Skywagon',
        'Cessna 190/195',
        'Cessna 206 Stationair',
        'Cessna 210 Centurion',
        'Cessna 310',
        'Cessna 337 Skymaster',
        'Cirrus SR20',
        'Cirrus SR22',
        'Diamond DA20',
        'Diamond DA40',
        'Diamond DA42 Twin Star',
        'Diamond DA50',
        'Ercoupe 415',
        'Grumman AA-1',
        'Grumman AA-5',
        'Guimbal Cabri G2',
        'Mooney M20',
        'Mooney Ovation',
        'Mooney Acclaim',
        'Piper Arrow',
        'Piper Archer',
        'Piper Aztec',
        'Piper Cherokee PA-28',
        'Piper Cherokee Six',
        'Piper Comanche',
        'Piper Cub',
        'Piper Dakota',
        'Piper J-3 Cub',
        'Piper Malibu',
        'Piper M350',
        'Piper M500',
        'Piper M600',
        'Piper Navajo',
        'Piper PA-18 Super Cub',
        'Piper PA-20 Pacer',
        'Piper PA-22 Tri-Pacer',
        'Piper PA-24 Comanche',
        'Piper PA-28 Cherokee',
        'Piper PA-28R Arrow',
        'Piper PA-32 Saratoga',
        'Piper PA-34 Seneca',
        'Piper PA-44 Seminole',
        'Piper PA-46 Malibu',
        'Robinson R22',
        'Robinson R44',
        'Socata TB9 Tampico',
        'Socata TB10 Tobago',
        'Socata TB20 Trinidad',
        'Socata TBM 700',
        'Socata TBM 850',
        'Tecnam P2006T',
        'Vans RV-6',
        'Vans RV-7',
        'Vans RV-8',
        'Vans RV-9',
        'Vans RV-10',
        'Vans RV-12'
      ],
      medical: [
        "First Class",
        "Second Class", 
        "Third Class"
      ]
    };
  },
  methods: {

    // COMPONENT NAVIGATION METHODS //

    open(userUid) {
      this.dialog = true;
      this.userUid = userUid;
      this.loadUserProfile(userUid);
    },
    async saveProfile() {
      try {
        if (!this.userUid) throw new Error("User UID is not provided.");
        await updateUserProfile(this.userUid, this.userInfo);
        this.dialog = false;
        this.$emit('profileUpdated');
      } catch (error) {
        console.error("Error saving user profile:", error);
      }
    },
    close() {
      this.$emit('update:value', false);
    },

    // CONTENT LOADING METHODS //

    async loadUserProfile(userUid) {
      try {
        const profileData = await fetchUserProfile(userUid);
        this.userInfo = { ...this.userInfo, ...profileData };
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    },

    // FORMATTING METHODS //

    formatPhoneNumber() {
      if(this.userInfo.phone) {
        this.userInfo.phone = this.userInfo.phone.replace(/\D/g, ''); // Remove non-digit characters
        // Format to (XXX) XXX-XXXX
        this.userInfo.phone = this.userInfo.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }
    },
    formatBirthday(event) {
      let input = event.target.value;

      // Remove all characters except digits and slashes
      input = input.replace(/[^\d/]/g, '');

      // Automatically insert slashes after month and day, allowing four digits for the year
      input = input.replace(/^(\d{2})(\d)$/, '$1/$2') // MM/D -> MM/D/
                  .replace(/^(\d{2})\/(\d{2})(\d)$/, '$1/$2/$3') // MM/DD/D -> MM/DD/D/
                  .replace(/^(\d{2})(\d{2})$/, '$1/$2') // MMD -> MM/D
                  .replace(/^(\d{2})(\d{2})(\d{4})$/, '$1/$2/$3'); // MMDDYYYY -> MM/DD/YYYY

      // Limit the length to 10 characters (MM/DD/YYYY)
      input = input.substring(0, 10);

      this.userInfo.birthday = input;
    },
    formatMedicalExpiration(event) {
      let input = event.target.value;

      // Remove all characters except digits and slashes
      input = input.replace(/[^\d/]/g, '');

      // Automatically insert slashes after month and day, allowing four digits for the year
      input = input.replace(/^(\d{2})(\d)$/, '$1/$2') // MM/D -> MM/D/
                  .replace(/^(\d{2})\/(\d{2})(\d)$/, '$1/$2/$3') // MM/DD/D -> MM/DD/D/
                  .replace(/^(\d{2})(\d{2})$/, '$1/$2') // MMD -> MM/D
                  .replace(/^(\d{2})(\d{2})(\d{4})$/, '$1/$2/$3'); // MMDDYYYY -> MM/DD/YYYY

      // Limit the length to 10 characters (MM/DD/YYYY)
      input = input.substring(0, 10);

      this.userInfo.medicalExpiration = input;
    },
    formatName(event) {
      let name = event.target.value;
      
      // Capitalize the first letter of each word
      name = name
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');

      this.userInfo.name = name;

      // Prevent cursor jumping by setting the caret position correctly
      this.$nextTick(() => {
        let cursorPosition = event.target.selectionStart;
        // Adjust the cursor position for capitalized letters.
        const delta = name.length - event.target.value.length;
        event.target.setSelectionRange(cursorPosition + delta, cursorPosition + delta);
      });
    },
    formatAddress() {
      if (this.userInfo.address) {
        const stateAbbreviations = [
          "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
        ];
        const directions = ["N", "S", "E", "W", "NE", "NW", "SE", "SW"];

        this.userInfo.address = this.userInfo.address
          .split(' ')
          .map(word => {
            // Check if the word is a state abbreviation or a direction
            if (stateAbbreviations.includes(word.toUpperCase()) || directions.includes(word.toUpperCase())) {
              return word.toUpperCase();
            }
            // Capitalize the first letter of each word and lowercase the rest
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          })
          .join(' ');
      }
    },
    formatAircraft() {
      if (Array.isArray(this.userInfo.aircraft)) {
        this.userInfo.aircraft = this.userInfo.aircraft.map(aircraft => {
          if (typeof aircraft === 'string') {
            return aircraft.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
          }
          return aircraft;
        });
      }
    }
  }
};
</script>

<style scoped>
.dialog-class {
  width: 80vw;
}

.card-container {
  display: flex;
  flex-direction: column;
  height: 90vh;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.title-text {
  color: rgba(var(--v-theme-accent));
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.scrollable-content {
  height: 100%;
  margin-right: 10px;
  margin-block: 10px;
  overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-accent), 0.1);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-accent), 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-accent), 0.8);
}

.text-field {
  color: #91b9c9 !important;
  margin-block: 4px;
}

.section-title {
  color: rgb(var(--v-theme-primary));
  background-color: rgba(var(--v-theme-accent), 0.5);
  backdrop-filter: blur(10px);
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.section-text {
  color: rgb(var(--v-theme-accent));
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 100vw;
  }

  .card-container {
    height: 90vh;
    margin-left: 0px;
  }

  .scrollable-content {
    margin-right: 0px;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 600px) {
  .small-container {
    padding-inline: 0px;
  }

  .dialog-class {
    width: 100vw;
  }

  .card-container {
    height: 100vh;
    margin-left: 0px;
  }
}
</style>
