<!-- src/components/ViewLesson.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">
    <v-card class="card-container">
      <v-card-title class="title-text">
        <div class="toggle-container">
          <v-btn-toggle 
            v-model="selectedView" 
            mandatory 
            variant="flat"
            base-color="primary"
            density="comfortable"
          >
            <v-btn value="debrief">Debrief</v-btn>
            <v-btn value="notes">Notes</v-btn>
          </v-btn-toggle>
        </div>
      </v-card-title>

      <v-row class="section-row mx-8" v-if="selectedView === 'debrief'">
          <v-icon class="ml-5">mdi-auto-fix</v-icon>
          <v-card-text class="lesson-notes ma-0"> AI debrief generated by Amelia </v-card-text>
      </v-row>

      <v-row class="section-row mx-8" v-if="selectedView === 'notes'">
          <v-icon class="ml-5">mdi-note-text</v-icon>
          <v-card-text class="lesson-notes ma-0"> Lesson notes from instructor </v-card-text>
      </v-row>
      
      <!-- Detailed Information Section -->
      <v-card-text>
        <!-- Lesson Content -->
        <div class="scrollable-content">
          <v-card-text v-if="selectedView === 'debrief'" class="lesson-notes py-2">
            <v-card class="feedback-card mb-3" variant="flat">
              <v-card-title class="mt-2">
                <v-icon class="mr-2">mdi-thumb-up</v-icon>
                Key Feedback
              </v-card-title>
              <v-card-text>
                <div v-html="parsedDebrief.keyFeedback"></div>
              </v-card-text>
            </v-card>
            
            <v-card class="improvement-card mb-3" variant="flat">
              <v-card-title class="mt-2">
                <v-icon class="mr-2">mdi-alert-circle</v-icon>
                Areas for Improvement
              </v-card-title>
              <v-card-text>
                <div v-html="parsedDebrief.areasForImprovement"></div>
              </v-card-text>
            </v-card>
            
            <v-card class="highlights-card mb-3" variant="flat">
              <v-card-title class="mt-2">
                <v-icon class="mr-2">mdi-star-circle</v-icon>
                Positive Highlights
              </v-card-title>
              <v-card-text>
                <div v-html="parsedDebrief.positiveHighlights"></div>
              </v-card-text>
            </v-card>
            
            <v-card class="next-card" variant="flat">
              <v-card-title class="mt-2">
                <v-icon class="mr-2">mdi-road-variant</v-icon>
                Next Steps
              </v-card-title>
              <v-card-text>
                <div v-html="parsedDebrief.nextSteps"></div>
              </v-card-text>
            </v-card>
          </v-card-text>

          <v-card-text v-if="selectedView === 'notes'" class="lesson-notes py-2"> 
            <div v-html="formattedLessonNotes"></div> 
          </v-card-text>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MarkdownIt from 'markdown-it';

export default {
  name: 'ViewLesson',
  data() {
    return {
      dialog: false,
      lesson: [],
      selectedView: 'debrief'
    };
  },
  methods: {
    open(lesson) {
      this.dialog = true;
      this.selectedView = 'debrief';
      this.lesson = lesson;
    },
    formatDate(timestamp) {
      if (!timestamp) return '';
      const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
      const date = new Date(milliseconds);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    },
    parseMarkdown(text) {
      if (!text) return '';

      const md = new MarkdownIt();
      let htmlContent = md.render(text);

      return htmlContent;
    },
    extractSection(text, label) {
      const regex = new RegExp(`\\*\\*${label}:\\*\\*(.*?)(?=\\*\\*|$)`, 's');
      const match = text.match(regex);
      return match ? this.parseMarkdown(match[1].trim()) : '';
    }
  },
  computed: {
    parsedDebrief() {
      const debrief = this.lesson.lessonDebrief || '';
      return {
        keyFeedback: this.extractSection(debrief, 'Key Feedback'),
        areasForImprovement: this.extractSection(debrief, 'Areas for Improvement'),
        positiveHighlights: this.extractSection(debrief, 'Positive Highlights'),
        nextSteps: this.extractSection(debrief, 'Next Steps')
      };
    },
    formattedLessonNotes() {
      if (!this.lesson.lessonNotes) {
        return 'No notes were added for this lesson.';
      }

      const lessonNotes = this.lesson.lessonNotes;

      // Check if the lesson contains conversation turns by checking for Instructor and Student labels
      const hasTurns = lessonNotes.includes('Instructor:') || lessonNotes.includes('Student:');

      if (hasTurns) {
        // Split the text into lines based on newlines
        const lines = lessonNotes.split('\n').map((line) => {
          // Apply bold formatting for Instructor and Student labels
          if (line.startsWith('Instructor:')) {
            return `<div style="padding-bottom: 8px;"><strong>Instructor:</strong> ${line.replace('Instructor:', '').trim()}</div>`;
          }
          if (line.startsWith('Student:')) {
            return `<div style="padding-bottom: 8px;"><strong>Student:</strong> ${line.replace('Student:', '').trim()}</div>`;
          }
          // For any other lines, return them as is with regular padding
          return `<div style="padding-bottom: 8px;">${line.trim()}</div>`;
        });
        // Join the lines back together into a single HTML string
        return lines.join('');
      } else {
        // If there are no conversation markers, return the lesson notes as a simple block of text
        return lessonNotes.replace(/\n/g, '<br>');
      }
    }
  }
};
</script>

<style scoped>
.dialog-class {
  width: 70vw;
}

.card-container {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
  max-height: 60vh;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.title-text {
  color: rgba(var(--v-theme-accent));
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.toggle-container {
  display: flex;
  justify-content: center;
}

.lesson-subtext {
  color: rgba(var(--v-theme-accent));
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;
  white-space: normal;
}

.scrollable-content {
  flex-grow: 1;
  overflow-y: auto;
  min-height: 20vh;
  max-height: 40vh;
  margin-right: 10px;
  margin-block: 10px;
}

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-accent), 0.1);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-accent), 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-accent), 0.8);
}

.section-row {
  display: flex;
  margin-inline: 10px;
  margin-top: 10px;
  align-items: center;
  background-color: rgba(var(--v-theme-accent), 0.1); 
  color: rgb(var(--v-theme-accent));
  border-radius: 5px;
}

.lesson-notes {
  margin: 10px;
  color: rgb(var(--v-theme-accent));
}

:deep(.lesson-notes) p {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.lesson-notes) h1 {
  margin-inline: 20px;
  margin-block: 20px;
}

:deep(.lesson-notes) h2 {
  margin-inline: 20px;
  margin-block: 20px;
}

:deep(.lesson-notes) h3 {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.lesson-notes) h4 {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.lesson-notes) h5 {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.lesson-notes) ul {
  margin-inline: 30px;
  margin-block: 10px;
}

:deep(.lesson-notes) ol {
  margin-inline: 30px;
  margin-block: 10px;
}

:deep(.lesson-notes) li {
  margin-inline: 30px;
  margin-block: 10px;
}

:deep(.lesson-notes) hr {
  margin-inline: 20px;
  border: none;
  height: 2px;
  background-color: white;
}

:deep(.lesson-notes) table {
  margin-inline: 30px;
  margin-block: 20px;
  width: 90%;
  border-collapse: collapse;
}

:deep(.lesson-notes) th {
  padding-block: 10px;
  text-align: center;
  border: 1px solid white;
}

:deep(.lesson-notes) td {
  padding-block: 5px;
  text-align: center;
  font-size: 14px;
  border: 1px solid white;
}

:deep(.lesson-notes) pre {
  margin-inline: 20px;
  margin-block: 20px;
  padding: 10px;
  background-color: #282c34;
  color: #f8f8f2;
  border-radius: 5px;
  overflow: auto;
}

:deep(.lesson-notes) code {
  width: 100%;
  background-color: #282c34;
  color: #f8f8f2;
  padding: 2px 4px;
  border-radius: 3px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
}

:deep(.lesson-notes) pre code {
  padding: 0;
}

.feedback-card {
  background-color: rgba(var(--v-theme-primary));
  color: white;
}

.improvement-card {
  background-color: rgba(var(--v-theme-warning));
  color: white;
}

.highlights-card {
  background-color: rgba(var(--v-theme-success));
  color: white;
}

.next-card {
  background-color: rgba(var(--v-theme-info));
  color: white;
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 100vw;
  }

  .card-container {
    min-height: 40vh;
    max-height: 80vh;
    margin-left: 0px;
  }

  .lesson-notes {
    margin: 0px;
  }

  .scrollable-content {
    min-height: 30vh;
    max-height: 60vh;
    margin-right: 0px;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 600px) {
  .card-container {
    min-height: 80vh;
    max-height: 100vh;
    margin-left: 0px;
  }

  .scrollable-content {
    min-height: 50vh;
    max-height: 67.5vh;
    margin-right: 0px;
  }
}
</style>
