<!-- src/components/StudentLessons.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">
    <v-card class="card-container">
      <v-card-title class="title-text">Lesson History</v-card-title>

      <v-card-text class="scrollable-content">
        <v-card
          v-for="flight in flights"
          :key="flight"
          variant="outlined"
          color="accent"
          class="lesson-card"
        >
          <!-- Card header -->
          <v-row>
            <v-col cols="8">
              <v-card-title> {{ formatDate(flight.timestamp) }} </v-card-title>
            </v-col>
            <v-col cols="4" class="d-flex align-center justify-end">
              <v-chip
                v-if="flight.instructorName && !$vuetify.display.mobile"
                color="accent"
                variant="tonal"
                prepend-icon="mdi-account-tie"
                :text="flight.instructorName"
                class="mx-3"
              >
              </v-chip>
            </v-col>
          </v-row>

          <!-- Lesson Debrief Sections -->
          <v-card v-if="flight.lessonDebrief" class="feedback-card mb-3" variant="flat">
            <v-card-title class="mt-2">
              <v-icon class="mr-2">mdi-thumb-up</v-icon>
              Key Feedback
            </v-card-title>
            <v-card-text v-html="extractSection(flight.lessonDebrief, 'Key Feedback')"></v-card-text>
          </v-card>

          <v-card v-if="flight.lessonDebrief" class="improvement-card mb-3" variant="flat">
            <v-card-title class="mt-2">
              <v-icon class="mr-2">mdi-alert-circle</v-icon>
              Areas for Improvement
            </v-card-title>
            <v-card-text v-html="extractSection(flight.lessonDebrief, 'Areas for Improvement')"></v-card-text>
          </v-card>

          <v-card v-if="flight.lessonDebrief" class="highlights-card mb-3" variant="flat">
            <v-card-title class="mt-2">
              <v-icon class="mr-2">mdi-star-circle</v-icon>
              Positive Highlights
            </v-card-title>
            <v-card-text v-html="extractSection(flight.lessonDebrief, 'Positive Highlights')"></v-card-text>
          </v-card>

          <v-card v-if="flight.lessonDebrief" class="next-card" variant="flat">
            <v-card-title class="mt-2">
              <v-icon class="mr-2">mdi-road-variant</v-icon>
              Next Steps
            </v-card-title>
            <v-card-text v-html="extractSection(flight.lessonDebrief, 'Next Steps')"></v-card-text>
          </v-card>

          <!-- Fallback if no lesson debrief -->
          <v-card-text v-else-if="flight.lessonNotes" class="lesson-notes py-2"> {{ flight.lessonNotes }} </v-card-text>
          <v-card-text v-else class="lesson-notes py-2"> There is no feedback available for this lesson. </v-card-text>
          
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MarkdownIt from 'markdown-it';

export default {
  name: 'StudentLessons',
  data() {
    return {
      dialog: false,
      flights: []
    };
  },
  methods: {
    open(flights) {
      this.dialog = true;
      this.flights = flights;
    },
    formatDate(timestamp) {
      if (!timestamp) return '';
      const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
      const date = new Date(milliseconds);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    },
    parseMarkdown(text) {
      if (!text) return '';
      const md = new MarkdownIt();
      return md.render(text);
    },
    extractSection(text, label) {
      const regex = new RegExp(`\\*\\*${label}:\\*\\*(.*?)(?=\\*\\*|$)`, 's');
      const match = text.match(regex);
      return match ? this.parseMarkdown(match[1].trim()) : '';
    }
  }
};
</script>

<style scoped>
.dialog-class {
  width: 80vw;
}

.card-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.title-text {
  color: rgba(var(--v-theme-accent));
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.card-text-area {
  color: rgba(var(--v-theme-accent));
  text-align: center;
  font-size: 14px;
  word-wrap: break-word;
  white-space: normal;
}

.lesson-card {
  margin-inline: 30px;
  margin-block: 10px;
}

.feedback-card {
  background-color: rgba(var(--v-theme-primary));
  color: white;
  margin: 10px;
}

.improvement-card {
  background-color: rgba(var(--v-theme-warning));
  color: white;
  margin: 10px;
}

.highlights-card {
  background-color: rgba(var(--v-theme-success));
  color: white;
  margin: 10px;
}

.next-card {
  background-color: rgba(var(--v-theme-info));
  color: white;
  margin: 10px;
}

.scrollable-content {
  height: 100%;
  margin-right: 10px;
  overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-accent), 0.1);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-accent), 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-accent), 0.8);
}

.section-row {
  display: flex;
  margin-inline: 10px;
  margin-block: 5px;
  align-items: center;
  background-color: rgba(var(--v-theme-accent), 0.1); 
  color: rgb(var(--v-theme-accent));
  border-radius: 5px;
}

.notes-section {
  padding-block: 0;
  padding-left: 0;
  font-weight: 500;
}

.lesson-notes {
  margin: 10px;
  color: rgb(var(--v-theme-accent));
}

:deep(.lesson-notes) p {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.lesson-notes) h1 {
  margin-inline: 20px;
  margin-block: 20px;
}

:deep(.lesson-notes) h2 {
  margin-inline: 20px;
  margin-block: 20px;
}

:deep(.lesson-notes) h3 {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.lesson-notes) h4 {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.lesson-notes) h5 {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.lesson-notes) ul {
  margin-inline: 30px;
  margin-block: 10px;
}

:deep(.lesson-notes) ol {
  margin-inline: 30px;
  margin-block: 10px;
}

:deep(.lesson-notes) li {
  margin-inline: 30px;
  margin-block: 10px;
}

:deep(.lesson-notes) hr {
  margin-inline: 20px;
  border: none;
  height: 2px;
  background-color: white;
}

:deep(.lesson-notes) table {
  margin-inline: 30px;
  margin-block: 20px;
  width: 90%;
  border-collapse: collapse;
}

:deep(.lesson-notes) th {
  padding-block: 10px;
  text-align: center;
  border: 1px solid white;
}

:deep(.lesson-notes) td {
  padding-block: 5px;
  text-align: center;
  font-size: 14px;
  border: 1px solid white;
}

:deep(.lesson-notes) pre {
  margin-inline: 20px;
  margin-block: 20px;
  padding: 10px;
  background-color: #282c34;
  color: #f8f8f2;
  border-radius: 5px;
  overflow: auto;
}

:deep(.lesson-notes) code {
  width: 100%;
  background-color: #282c34;
  color: #f8f8f2;
  padding: 2px 4px;
  border-radius: 3px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
}

:deep(.lesson-notes) pre code {
  padding: 0;
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 100vw;
  }

  .card-container {
    height: 90vh;
    margin-left: 0px;
  }

  .scrollable-content {
    margin-right: 0px;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 600px) {
  .lesson-card {
    margin-inline: 0px;
  }
}
</style>
