<!-- src/components/InstructNavigationContent.vue -->

<template>
  <div class="drawer-content">
    <!-- Header -->
    <v-list bg-color="transparent">
      <v-list-item
        :prepend-avatar="userPhoto"
        :title="userName ? userName : 'FlightSense'"
        :subtitle="truncatedUserEmail"
        class="header-content"
      ></v-list-item>
    </v-list>

    <v-divider v-if="!$vuetify.display.mobile"></v-divider>

    <v-spacer></v-spacer>

    <v-divider></v-divider>

    <!-- Footer -->
    <v-list bg-color="transparent">
      <v-list-item
        v-if="isPaidUser"
        prepend-icon="mdi-account-cash"
        title="Billing"
        rounded="true"
        class="list-item-footer"
        @click="openBillingPortal"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-account-circle"
        title="User Profile"
        rounded="true"
        class="list-item-footer"
        @click="openUserProfile"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-logout"
        title="Logout"
        rounded="true"
        class="list-item-footer"
        @click="signOut"
      ></v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    userPhoto: String,
    userName: String,
    truncatedUserEmail: String,
    isPaidUser: Boolean,
    selectedView: String,
  },
  methods: {
    openBillingPortal() {
      this.$emit('open-billing-portal');
    },
    openUserProfile() {
      this.$emit('open-user-profile');
    },
    signOut() {
      this.$emit('sign-out');
    },
  },
};
</script>

<style scoped>
.drawer-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-content {
  color: rgb(var(--v-theme-accent));
  margin: 4px;
}

.list-item-footer {
  color: rgb(var(--v-theme-accent));
  margin-inline: 4px;
  border-radius: 10px;
}
</style>
