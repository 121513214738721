<!-- src/components/SnackbarNotification.vue -->

<template>
  <v-snackbar v-model="show" :color="color" :timeout="timeout">
    {{ message }}
    <template v-slot:actions="{ attrs }">
      <v-btn icon density="compact" v-bind="attrs" @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'SnackbarNotification',
  props: {
    message: String,
    color: {
      type: String,
      default: 'success'
    },
    timeout: {
      type: Number,
      default: 5000
    }
  },
  data() {
    return {
      show: false
    };
  },
  watch: {
    message(newVal) {
      if (newVal) {
        this.show = true;
      }
    }
  }
};
</script>
