import { auth, firebaseConfig } from "../../firebaseConfig";

//---------------------------------------------------------------------
// Thread API
//---------------------------------------------------------------------

export const getThreads = async () => {
  try {
    return await callGet(`${firebaseConfig.apiUrl}/threads`);
  } catch (error) {
    console.error('Error fetching threads:', error);
    throw error;
  }
}

export const getThreadSummaries = async () => {
  try {
    return await callGet(`${firebaseConfig.apiUrl}/thread-summaries`);
  } catch (error) {
    console.error('Error fetching thread summaries:', error);
    throw error;
  }
}

export const getThread = async (threadUid) => {
  try {
    return await callGet(`${firebaseConfig.apiUrl}/threads/${threadUid}`);
  } catch (error) {
    console.error('Error fetching thread:', error);
    throw error;
  }
}

export const postThread = async () => {
  try {
    return await callPost(`${firebaseConfig.apiUrl}/threads`, {});
  } catch (error) {
    console.error('Error creating thread:', error);
    throw error;
  }
};

//---------------------------------------------------------------------
// Chat API
//---------------------------------------------------------------------

export const getChatStream = async (threadUid, model) => {
  const url = `${firebaseConfig.apiUrl}/threads/${threadUid}/chat/stream`;
  await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'text/plain',
      'Authorization': `Bearer ${await getAuthToken()}`
    }
  }).then(async (res) => {
    const reader = res.body.getReader();
    const decoder = new TextDecoder();
    let result = '';

    const responseIndex = model.activeConversationResponseIndex;
    const responseHolder = model.conversation[responseIndex]
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      result += decoder.decode(value, { stream: true });
      responseHolder.content = result;
    }
  }).catch(error => {
    console.error('Error fetching stream:', error);
    throw error;
  });
}

export const postChatMessage = async (threadUid, message) => {
  const payload = {
    message: message,
    type: 'user',
    citations: []
  }
  return await callPost(`${firebaseConfig.apiUrl}/threads/${threadUid}/messages`, payload);
}

//---------------------------------------------------------------------
// Helpers
//---------------------------------------------------------------------

async function getAuthToken() {
  return await auth.currentUser.getIdToken();
}

async function callPost(url, body) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAuthToken()}`
    },
    body: JSON.stringify(body)
  });

  if (!response.ok) {
    console.error('Error creating thread:', response.statusText);
    throw new Error(`Error creating thread: ${response.statusText}`);
  }

  const data = await response.json();
  return data;
}

async function callGet(url) {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAuthToken()}`
    }
  });

  if (!response.ok) {
    console.error('Error fetching threads:', response.statusText);
    throw new Error(`Error fetching threads: ${response.statusText}`);
  }

  const data = await response.json();
  return data;
}