// src/index.js

import { createRouter, createWebHistory } from 'vue-router';
import { auth, analytics } from '../../firebaseConfig';
import LandingPage from '../pages/LandingPage.vue';
import ChatPage from '../pages/ChatPage.vue';
import InstructPage from '../pages/InstructPage.vue';
import { onAuthStateChanged } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
  },
  {  
    path: '/chat',
    name: 'ChatPage',
    component: ChatPage,
    meta: { requiresAuth: true } // Protect this route
  },
  {
    path: '/instruct',
    name: 'InstructPage',
    component: InstructPage,
    meta: { requiresAuth: true } // Protect this route
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Function to determine if the user is authenticated
function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const user = await getCurrentUser();

  if (requiresAuth && !user) {
    next('/'); // Redirect to landing page if not authenticated
  } else {
    next(); // Proceed as normal if authenticated or route doesn't require auth
  }
});

// Log page views using the afterEach hook
router.afterEach((to) => {
  logEvent(analytics, 'page_view', {
    screen_name: to.name, // Use the route name as the screen name
    screen_class: to.path, // Use the route path as the screen class
  });
});

export default router;
