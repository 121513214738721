// src/services/stripeService.js

import { v4 as uuidv4 } from 'uuid';
import { db } from "../../firebaseConfig";
import {
  doc,
  setDoc,
  getDoc,
  Timestamp,
  onSnapshot
} from "firebase/firestore";
import { Sentry } from '@/plugins/sentry.js';
import { analytics } from '../../firebaseConfig';
import { logEvent } from 'firebase/analytics';
const stripe = require('stripe')(process.env.VUE_APP_STRIPE_KEY);

const createCheckoutSession = async (userId, priceId) => {
  try {
    const uniqueSessionId = uuidv4();
    const sessionDocRef = doc(db, `users/${userId}/checkout_sessions`, uniqueSessionId);
    const baseUrl = `${process.env.VUE_APP_BASE_URL}/chat`;

    const checkoutDetails = {
      price: priceId,
      success_url: baseUrl,
      cancel_url: baseUrl,
      mode: 'subscription',
      allow_promotion_codes: true,
      automatic_tax: { enabled: true },
      created: Timestamp.now()
    };

    await setDoc(sessionDocRef, checkoutDetails);

    logEvent(analytics, 'checkout_session_initiated', { userId: userId });
    
    return sessionDocRef.id;
  } catch (error) {
    Sentry.captureMessage("Error creating checkout session");
    Sentry.captureException(error);
    console.error("Error creating checkout session:", error);
    throw error;
  }
};

const createProfessionalTrialSubscription = async (userId, priceId) => {
  try {
    // TODO - Move this to the backend
    // Fetch user document from Firestore
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User not found in Firestore');
    }

    // Get stripeId from the user document
    const customerId = userDoc.data().stripeId;


    // Create a Professional subscription in Stripe
    const subscription = await stripe.subscriptions.create({
      customer: customerId,
      items: [{ price: priceId }],
      trial_period_days: 30,
      trial_settings: {
        end_behavior: {
          missing_payment_method: 'cancel',
        },
      }
    });

    logEvent(analytics, 'free_professional_trial_initiated', { userId: userId });

    return subscription.id;
  } catch (error) {
    Sentry.captureMessage("Error creating free trial");
    Sentry.captureException(error);
    console.error("Error creating trial subscription:", error);
    throw error;
  }
};

const listenToSession = (userId, sessionId, onSessionUrlReady) => {
  const sessionRef = doc(db, `users/${userId}/checkout_sessions`, sessionId);

  return onSnapshot(sessionRef, (doc) => {
    const data = doc.data();
    if (data && data.url) {
      onSessionUrlReady(data.url);
    }
  });
};

export { createCheckoutSession, createProfessionalTrialSubscription, listenToSession };
